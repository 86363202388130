import React from 'react'
import './browserbanner.css'
import { Link } from 'react-router-dom'

function BrowserBanner({BannerData, className}) {
  return (
    <div className={className}>
      {BannerData?.map((item, index)=>(
                 <div className="col" key={index}>
                 <img src={item.image} loading="lazy"/>
                 <div className='overlay'>
                     <h4>{item.title}</h4>
                     <Link to={item.url} className='btn'>{item.btntext}</Link>
                 </div>
         </div>
      ))}
    </div>
  )
}

export default BrowserBanner