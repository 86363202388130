import React, { useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Popup from "../../components/common/popup/Popup";
import { useEffect } from "react";

export default function DeletePaymentMenthodPopup({ isopen, handleClose, update, items }) {
  const token = sessionStorage.getItem("token");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const initialized = useRef(false);
  const [paymentTab, setPaymentTab] = useState("tab1");
 const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

    }
  }, [items]);

  const handleDelete = () => {
    if (isChecked) {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      const raw = JSON.stringify({
        id: items ? items._id : ''
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/payment/deletepmbyid`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        if (response.ok) {
            setIsUpdate(true);
            setTimeout(() => {
              setIsUpdate(false);
              handleClose();
              update();
            }, 3000);
          }else{
            setIsError(true);
            setErrorMsg(paresData.message);
            setTimeout(() => {
              setIsError(false);
            }, 3000);
          }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    else{
      setIsError(true);
      setErrorMsg('PLEASE SELECT CHECKBOX FOR DELETE ACCOUNT DATA');
      setTimeout(() => {
        setIsError(false);
      }, 3000);
    }
    
  }

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
   };

  return (
    <>
      <Modal
        show={isopen}
        onHide={handleClose}
        className="menu-dropdown-popup stack-chnage-popup paymentDepositePopup manage-acccount-popup"
        dialogClassName="v-center"
      >
        <Modal.Header closeButton>
          <h2>
            {" "}
            <span className="title-v-border"></span> Confirmation form
          </h2>
        </Modal.Header>
        <Modal.Body>
        <div className="change-pass-model-form-inputs">
          <span>do you want to delete this payment method?</span>
          <input
                      className="checkbox onclickStatus"
                      name="oneclickbet"
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e)=>handleCheckboxChange(e)}
                    />
        </div>
        <div className="btn-sec">
          <button type="submit" className="btn" onClick={handleDelete}>Submit</button>
        </div>
        </Modal.Body>
      </Modal>

      <Popup
        popupClass={`alertMsgpopup ${isUpdate ? "open" : ""}`}
        content={
          <div className={`alert-msg success`}>
            <span className="msg">DELETED SUCESSFULLY!!</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />

      <Popup
        popupClass={`alertMsgpopup ${isError ? "open" : ""}`}
        content={
          <div className={`alert-msg err`}>
            <span className="msg">{errorMsg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </>
  );
}