import React, { useState, useEffect,useRef, useContext } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Container from "../../components/common/Container";
import { Utils } from "../../Utils";
import { useNavigate } from "react-router-dom";
import { PopupContext } from "../../components/LoginPopup/LoginPopupContext";
import AddPaymentMenthodPopup from "./AddPaymentMenthodPopup";
import EditPaymentMethodPopup from "./EditPaymentMethodPopup";
import DeletePaymentMenthodPopup from "./DeletePaymentMenthodPopup";

export default function ManageAccounts() {
    document.title = "Manage Accounts";
    const initialized = useRef(false);
    const token = sessionStorage.getItem("token");
    const [betDetails, setBetDetails] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedTypes, setSelectedTypes] = useState('All');
    const [hasMore, setHasMore] = useState(true);
    const [isAddAccount, setIsAddAccount] = useState(false);
    const [isDeleteAccount, setIsDeleteAccount] = useState(false);
    const [isEditAccount, setIsEditAccount] = useState(false);
    const [gettab, setTab] = useState('');
    const [getitems, setitems] = useState();

    const { loggedIn } = useContext(PopupContext);
    const [isLoggedIn, setIsLoggedIn] = useState(() => {
    const storedValue = sessionStorage.getItem('loggedIn');
        return storedValue === "true";
    });
  
    const handleAddAccountAdd = () => {
        setIsAddAccount(true);
    }

    const closePopup = () => {
        setIsAddAccount(false);
        setIsEditAccount(false);
        setIsDeleteAccount(false);
        setTab('');
        setitems();
    }

    useEffect(()=>{
        // if(!isLoggedIn || loggedIn){
        //     navigate('/')
        // }
    },[isLoggedIn, loggedIn])

    

      const handleTypeChange = (event) => {
        setHasMore(true);
        setBetDetails([]);
        setCurrentPage(0);
        setSelectedTypes(event.target.value);
        callMyBets(event.target.value, 0)
      };

      const callMyBets =(type, page)=>{
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        // myHeaders.append("Authorization", `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBIjoiNjczYzYzY2NmNjU0ZmQxYjYyOGY3ODM4IiwiaWF0IjoxNzM3NDM2OTc4LCJleHAiOjE3MzkyMzY5Nzh9.3JSqvTAAZ4i0WklHCZjyZIOLvll1n-nfUA2spThH6NA`);
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
          "page": page,
          "pmethod": type
        });
        
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/payment/paymentmethodlist`, requestOptions)
        .then(async (response) => {
            let paresData = await response.json();
            if (paresData.paymentMethodList.length === 0) {
                setHasMore(false);
              } else {
                setBetDetails(prevData => [...prevData, ...paresData.paymentMethodList]);
              }
              if (!response.ok) {

              }
            console.log("paymentmethodlist - ", paresData);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      const handleNextPage = () => {
        setCurrentPage(currentPage+1)
        callMyBets(selectedTypes,currentPage+1);
      }

      useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            callMyBets(selectedTypes,currentPage);
          }
      });

      const updatecall = () => {
        setBetDetails([]);
        callMyBets(selectedTypes,0);
      }

      const handleCheckboxChange = async (item, isChecked) => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`);
        myHeaders.append("Content-Type", "application/json");
      
        const raw = JSON.stringify({
          id: item._id // Use item's ID
        });
      
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
      
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/payment/pmstatusudpate`,
            requestOptions
          );
      
          const result = await response.json();
      
          if (response.ok) {
            console.log("Status updated successfully:", result);
            // Update the status in the local state
            setBetDetails((prevDetails) =>
              prevDetails.map((bet) =>
                bet._id === item._id ? { ...bet, status: isChecked } : bet
              )
            );
          } else {
            console.error("Failed to update status:", result.message);
          }
        } catch (error) {
          console.error("Error while updating status:", error);
        }
      };

    const getpaymentMethos = (data) => {
      if (data === "upiW") {
        return "UPI";
      }else if (data === "banktransferW") {
        return "Bank Transfer"; 
      }else if (data === "paytmW") {
        return "Paytm";
      }
    }

    
  return (
    <Container size='full'>
      <div className="account-sec">
          <div className="acount-header-filter">
            <div className="title-wraper">
                <span className="v-border"></span>
                <h3 className="title">Manage Accounts</h3>
            </div>
            <div className="right-filter">
              <div className="input-box">
                <select id="number-dropdown" value={selectedTypes} onChange={handleTypeChange}>
                  <option value="All">All</option>
                  <option value="banktransferW">Bank Transfer</option>
                  <option value="upiW">UPI</option>
                  <option value="paytmW">Paytm</option>
                </select>
              </div>
              <div className="btns">
                <span className="btn" onClick={handleAddAccountAdd}><i className="fa-solid fa-plus"></i>  Add Payment Method</span>
              </div>
            </div>
          </div>
          <div className="account-table">
            <table border="1" cellPadding="10" cellSpacing="0">
            <thead>
                <tr>
                  <th>Account Holder Name	</th>
                  <th>Account Number	</th>
                  <th>Ifsc Code	</th>
                  <th>Bank Name</th>
                  <th>Branch Name</th>
                  <th>Display Name</th>
                  <th>Payment Method</th>
                  <th>Status</th>
                  <th>Actions</th>
                  
                </tr>
              </thead>
              <tbody>
          {betDetails && betDetails.map((item,index)=>(
              <tr key={index} className={`account-statment-expand`}>
                  <td>{item.accountholdername || '-'}</td>
                  <td>{item.phonenumber || item.accountnumber || item.upiid || '-'}</td>
                  <td>{item.ifsccode || '-'}</td>
                  <td>{item.bankname || '-'}</td>
                  <td>{item.branchname || '-'}</td>
                  <td>{item.displayname || '-'}</td>
                  <td>{getpaymentMethos(item.pmethod) || '-'}</td>
                  <td> <div className="on-off-btn-section">
                  <span className="on-off">
                  OFF &nbsp;{" "}
                  <label className={`switch ${item.status ? 'on' : '' }`}>
                    <input
                      className="checkbox onclickStatus"
                      name="oneclickbet"
                      type="checkbox"
                      checked={item.status}
                      onChange={(e) =>handleCheckboxChange(item, e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                  &nbsp; ON
                </span></div></td>
                  <td>
                    <div className="btn-set">
                      <button className="btn edit-btn edit-paymentButton" onClick={()=>{
                        setIsEditAccount(true);
                        setitems(item);
                        if(item.pmethod === 'banktransferW') {
                          setTab('tab1');
                        } else if(item.pmethod === 'upiW') {
                          setTab('tab2');
                        } else {
                          setTab('tab3');
                        }
                        }} type="button">Edit</button>
                      <button className="btn delete-btn dlt-mthd" onClick={()=>{
                        setIsDeleteAccount(true);
                        setitems(item);
                      }} type="button">Delete</button>
                    </div>
                  </td>
              </tr>
          ))}
          </tbody>
            </table>
            {hasMore ? <div className="load-more-btn-sec"> <button onClick={handleNextPage} className="btn">Load More</button> </div> :''}
        </div>
      </div>

      <AddPaymentMenthodPopup isopen={isAddAccount} handleClose={closePopup} update={updatecall}/>
      <EditPaymentMethodPopup isopen={isEditAccount} handleClose={closePopup} update={updatecall} paymentTab={gettab} items={getitems} />
      <DeletePaymentMenthodPopup isopen={isDeleteAccount} handleClose={closePopup} update={updatecall} items={getitems} />

    </Container>
  )
}
