import React, { useContext, useEffect, useRef, useState } from 'react'
import { PopupContext } from '../components/LoginPopup/LoginPopupContext';
import { Link, useNavigate } from 'react-router-dom';

export default function FlotIcon() {

    const [data, setData] = useState(sessionStorage.getItem('sessionUpdatevalue'));
    const { loggedIn,sessionUpdatevalue } = useContext(PopupContext);
    const [count, setCount] = useState(0);
    const initialized = useRef(false);
    const navigate = useNavigate();

    const getBetsCount = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/bets/getopenbetcount`, requestOptions)
          .then(async (response) => {
            if (response.status === 200){
                let paresData = await response.json();
                setCount(paresData.count);
            }
          })
          .catch((error) => {
            // console.log("logInCheck - ",error);
          });
    }
    useEffect(() => {
        // if (!initialized.current) {
        //     initialized.current = true;
        //     getBetsCount();
        // }

        if (sessionStorage.getItem('token') || loggedIn ) {
          initialized.current = true;
            getBetsCount();
        }

        if (data !== sessionUpdatevalue) {
            setData(sessionUpdatevalue);
        }

    }, [sessionUpdatevalue,count,loggedIn]);


    const handleMyBets = () => {
        navigate('/mybets');
    }

    const handleClick = () => {
      if (localStorage.getItem('basicDetails')) {
        let dta = JSON.parse(localStorage.getItem('basicDetails'));
        const message = "Hello, I am interested in your services."; 
        const url = `https://wa.me/${dta?.contactNumber}?text=${encodeURIComponent(message)}`;
        window.open(url, "_blank");  
      }
    };

  return (
    <div className='sticky-link'>
    {sessionStorage.getItem('loggedIn') || loggedIn ? <div className="sticky-bets" onClick={handleMyBets}>
        <div className='count'>{count}</div>
        <div className='label'>Bets</div>
      </div> : ""}
      {JSON.parse(localStorage.getItem("isB2c") || "false") ? <div className="whatsup-app-link">
        <Link to='#' onClick={()=>handleClick()}>
          <div className="whatsapp_font_alg">Play and Win.Get a new Id instantly over whatsapp</div>
          <img src="../images/wp.png" alt='wp' className="whatsapp_icon_alg" />
        </Link>
        </div> :''}

    </div>
  )
}
