import React, { useContext, useEffect, useState } from 'react'
import Popup from '../../components/common/popup/Popup';
import OtpInput from '../../components/LoginPopup/OtpInput';
import { initializeOTPless } from '../../otpUtils';
import { callback } from '../../callback';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../UserContext';

export default function Registration() {
    const [isVerifed, setisVerifed] = useState(false);
    const { refCode } = useParams();
    const [loginSucessMsg, setLoginSucessMsg] = useState();
    const { sessionData, loggedIn } = useContext(UserContext);
    const navigate = useNavigate();
    const [agree, setAgree] = useState(false);
    const [isOTPsend, setisOTPsend] = useState(false);
  
    const [agreeMsg, setAgreeMsg] = useState(false);
  
    const [mobileNo, setMobileNo] = useState();
    const [password, setPassword] = useState();
    const [errMsg, setErrmsg] = useState(false);
    const [isSuccessMsg, setIsSuccessMsg] = useState(false);
    const [msg, setMsg] = useState('');
    const { setSessionData: setGlobalsession } = useContext(UserContext);
    const [showPassword, setShowPassword] = useState(false);
    const [domainName, setDomainName] = useState('');
  
    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(30); // Timer in seconds
    const [canResend, setCanResend] = useState(false); // Flag to enable/disable resend
    const { sendOTP,verifyOTP } = initializeOTPless(callback);
    const [formData, setFormData] = useState({
      fullName: "",
      userName:"",
      mobileNo: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
  
    const [errors, setErrors] = useState({
      fullName: "",
      userName: "",
      mobileNo: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
  
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
  
      // Clear the error as user types
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }; 
  
    const handleMobileNoChange = (e) => {
      let value = e.target.value;
      value = value.replace(/[^0-9+]/g, '');
      setMobileNo(value);
    };
  
    const handleAgreeChange = (e) => {
      setAgree(e.target.checked);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
    };
  
    const validateForm = () => {
      const newErrors = {};
  
      if (!formData.fullName) newErrors.fullName = "Full Name is required";
      if (!formData.userName) newErrors.userName = "User Name is required";
      if (!sessionStorage.getItem('mobileNo')) newErrors.mobileNo = "Mobile No is required";
      if (!formData.email) {
        newErrors.email = "Email is required";
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        newErrors.email = "Invalid email format";
      }
      if (!formData.password) newErrors.password = "Password is required";
      if (!formData.confirmPassword) {
        newErrors.confirmPassword = "Confirm Password is required";
      } else if (formData.password !== formData.confirmPassword) {
        newErrors.confirmPassword = "Passwords do not match";
      }
  
      return newErrors;
    };
  
    const handleRSubmit = (e) => {
      e.preventDefault();
  
      const newErrors = validateForm();
  
      // Update errors state
      setErrors(newErrors);
  
  
       // If no errors, proceed with form submission
       if (Object.keys(newErrors).length === 0) {
        const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      const raw = JSON.stringify({
        "whiteLabelName": window.location.hostname,
        "password": formData.password,
        "passwordConfirm": formData.confirmPassword,
        "userName": formData.userName,
        "contectNumber": sessionStorage.getItem('mobileNo'),
        "name": formData.fullName,
        "email": formData.email,
        "referalcode":refCode
      });
      
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
      
      fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/createUser`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("createUser :", data);
        if (data.status != "Error") {
          setIsSuccessMsg(true);
          setMsg("Successfully Signup!")
          setInterval(() => {
            setIsSuccessMsg(false);
            setMsg("");
          }, 2000);
          handleCloses();
        } else {
          setMsg(data.message)
          setErrmsg(true);
          setInterval(() => {
            setErrmsg(false);
            setMsg("");
          }, 2000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle login error
      });
      } else {
        // Focus the first field with an error
        const firstErrorField = Object.keys(newErrors)[0];
        document.getElementsByName(firstErrorField)[0].focus();
      }
    };
  
    useEffect(() => {
      if(loginSucessMsg){
        const setloginsucessMsg = setTimeout(() => {
          setLoginSucessMsg(false);
        }, 2000);
  
        return () => {
          clearTimeout(setloginsucessMsg);
        };
      }
    }, [sessionData]);
  
    const handleOtpChange = (newOtp) => {
      setOtp(newOtp);
      console.log("Updated OTP:", newOtp); // OTP value is updated here
    };
  
    
    const VerifyOTP = () => {
      verifyOTP(mobileNo, otp, '+91', {
        onSuccess: () => {
          setisVerifed(true);
          sessionStorage.setItem('mobileNo',mobileNo);
        },
        onError: (errorMessage) => {
          setErrmsg(true);
          setMsg("Incorrect OTP!");
          setInterval(() => {
            setErrmsg(false);
            setMsg("");
          }, 2000);
        }
      });
    }
  
    const OTPSend =() =>{
     const phoneRegex = /^[0-9]{10}$/; // Matches exactly 10 digits
     if (!phoneRegex.test(mobileNo)) {
      setAgreeMsg("Please enter a valid 10-digit mobile number.")
        setInterval(() => {
          setAgreeMsg();
        }, 5000);
     } else {
      if (agree) {
        sendOTP(mobileNo,'+91', {
          onSuccess: () => {
            setisOTPsend(true);
            console.log("OTP sent successfully!");
          },
          onError: (errorMessage) => {
            console.error("OTP sending failed:", errorMessage);
          }
        });
      }else{
        setAgreeMsg("To continue, kindly check the box to agree to our terms and conditions.")
        setInterval(() => {
          setAgreeMsg();
        }, 5000);
      }
     }
    }
  
    const handleCloses = () => {
      window.location.href = '/';
      setisVerifed(false);
      setisOTPsend(false);
      setCanResend(false);
      setOtp("");
    }
  
    useEffect(() => {
      let interval;
  
      if (isOTPsend && timer > 0) {
        interval = setInterval(() => {
          setTimer((prev) => prev - 1); // Decrement timer every second
        }, 1000);
      } else if (timer === 0 && isOTPsend) {
        setCanResend(true); // Allow resending OTP
      }
  
      return () => clearInterval(interval); // Cleanup interval on unmount
    }, [timer, isOTPsend]);
  
    const handleResend = () => {
      console.log("OTP resent!");
      OTPSend();
      setTimer(30); // Reset the timer to 30 seconds
      setCanResend(false); // Disable the button/text
    };
  
    return (
      <>
        <div className={`popup login-popup signup-popup open`}>
          <div className="popup-content">
            <div className="loginpopup-content">
              <div className="lp-row">
              <div className="lp-col">
                <div className="login-form-wraper">
                  <div className="logo">
                    <img
                      className="myloginmodl-form-logo"
                      src={`https://admin.${domainName}/logo/${domainName}2.webp`}
                      alt=""
                    />
                  </div>
                  {!isVerifed ? <form
                    method="post"
                    className="myloginmodl-form-dv"
                    onSubmit={handleSubmit}
                  >
  
                    <div className="input-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        name="mobileNumber"
                        onChange={handleMobileNoChange}
                        placeholder="e.g. +1234567890"
                        required
                        disabled={isOTPsend}
                        autoComplete="tel"
                      />
                    </div>
  
                    {isOTPsend ? <OtpInput onOtpChange={handleOtpChange}  />:''}
  
                    {isOTPsend ? (
                      canResend ? (
                        <button className="btn loginbtnn" onClick={handleResend}>Resend OTP</button>
                      ) : (
                        `Resend OTP in ${timer} seconds`
                      )
                    ) : ''}
  
                    {!isOTPsend ? <div className="input-group checkbox">
                      <input
                        type="checkbox"
                        name="agree"
                        id="checkbox1"
                        onChange={handleAgreeChange}
                      /><label htmlFor="checkbox1">I agree to the all terms & Conditions</label>
                    </div> : "" }
     
                    {agreeMsg && <div><span>{agreeMsg}</span></div>}
                    <div className="login-btns">
                    {isOTPsend ? <button
                        type="submit"
                        className="btn loginbtnn"
                        onClick={VerifyOTP}>
                        Verify OTP
                        </button> 
                      :<button
                        type="submit"
                        className="btn loginbtnn"
                        onClick={OTPSend}
                      >
                        Send OTP
                      </button>}
                      
                    </div>
                  </form> :'' }
                  
                  {isVerifed ? <form className="myloginmodl-form-dv signup-form" onSubmit={handleRSubmit}>
                    <h2>Sign Up</h2>
                    <div className="row">
                    <div className="input-group col-12">
                      <label>Full Name</label>
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                      />
                      {errors.fullName && <span className="error">{errors.fullName}</span>}
                    </div>
                    <div className="input-group col-12">
                      <label>Username</label>
                      <input
                        type="text"
                        name="userName"
                        value={formData.userName}
                        onChange={handleChange}
                        required
                      />
                      {errors.userName && <span className="error">{errors.userName}</span>}
                    </div>
                    <div className="input-group col-12">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                      {errors.password && <span className="error">{errors.password}</span>}
                    </div>
                    <div className="input-group col-12">
                      <label>Confirm Password</label>
                      <input
                        type="password"
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        required
                      />
                       {errors.confirmPassword && (
                        <span className="error">{errors.confirmPassword}</span>
                        )}
                    </div>
                    <div className="input-group col-12">
                      <label>Phone No.</label>
                      <input
                        type="text"
                        name="mobileNo"
                        style={{caretColor: "transparent",cursor:'not-allowed'}}
                        value={sessionStorage.getItem('mobileNo')}
                        onChange={handleChange}
                        required
                      />
                      {errors.mobileNo && <span className="error">{errors.mobileNo}</span>}
                    </div>
                    <div className="input-group col-12">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                      
                       {errors.email && <span className="error">{errors.email}</span>}
                    </div>
                    </div>
                    <button type="submit" className="btn loginbtnn">Sign Up</button> 
                  </form> :''}
                  
                </div>
              </div>
              </div>
              {/* <button onClick={()=>handleCloses()} className="btn login-close">
                BACK TO HOME
              </button> */}
            </div>
          </div>
        </div>
  
        <Popup
          popupClass={`alertMsgpopup ${isSuccessMsg ? "open" : ""}`}
          content={
            <div className={`alert-msg`}>
               <div className="close-btn" onClick={()=> setIsSuccessMsg(false)}>×</div>
              <span className="msg">{msg}</span>
              <div className="alert-progress-bar"></div>
            </div>
          }
        />
        <Popup
          popupClass={`alertMsgpopup Error ${errMsg ? "open" : ""}`}
          content={
            <div className={`alert-msg`}>
              <div className="close-btn" onClick={()=> setErrmsg(false)}>×</div>
              <span className="msg">{msg}</span>
              <div className="alert-progress-bar"></div>
            </div>
          }
        />
      </>
    );
}
