import React, { useContext, useEffect, useState } from 'react'
import Container from '../../components/common/Container'
import './userprofile.css'
import { Link, useNavigate } from 'react-router-dom';
import ChangePasswordPopup from '../../components/menudropdoenpopup/ChangePasswordPopup';
import { PopupContext } from '../../components/LoginPopup/LoginPopupContext';


export default function Profile() {
  document.title = 'Profile';
  const [userData, setUserData] =useState();
  const [bonusData, setBonusData] =useState();
  const [changePassword, setChangePassword] = useState(false);
  const token = sessionStorage.getItem('token');

  const handleClose = () => {setChangePassword(false);}
  const handleShow = () => { setChangePassword(true); }

  // const navigate = useNavigate();
//   const { loggedIn } = useContext(PopupContext);
//   const [isLoggedIn, setIsLoggedIn] = useState(() => {
//     const storedValue = sessionStorage.getItem('loggedIn');
//     return storedValue === "true";
//   });
  
//   useEffect(()=>{
//     if(!isLoggedIn || loggedIn){
//         navigate('/')
//     }
// },[isLoggedIn, loggedIn])

const callProfile = () =>{
  const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/Account/getMyProfile`, requestOptions)
.then(async (response) => {
  let paresData = await response.json();
  setUserData(paresData)
})
.catch((error) => {
  console.log(error);
});
}

const callBonus = () =>{
  const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/users/getbonusdetails`, requestOptions)
.then(async (response) => {
  let paresData = await response.json();
  console.log(paresData , 'bonus');
  setBonusData(paresData);
})
.catch((error) => {
  console.log(error);
});
}

const handleCopy = () => {
  navigator.clipboard.writeText(`${window.location.hostname}/registration/${userData.user.referalcode}`);
}

useEffect(() => {
  callProfile();
  callBonus();
}, [])

function maskContact(contact) {
  // Ensure contact is treated as a string
  const contactStr = String(contact);
  const contactLength = contactStr.length;
  const halfLength = Math.floor(contactLength / 2);

  // Mask the first half with '*' and return the concatenated string
  return '*'.repeat(halfLength) + contactStr.slice(halfLength);
}

function maskEmail(email) {
  // Split the email into the part before and after '@'
  const [username, domain] = email.split('@');

  // Mask the entire username with '*'
  const maskedUsername = '*'.repeat(username.length);

  // Return the masked email
  return `${maskedUsername}@${domain}`;
}

const formatDatefromAPI = (isoDate) => {
  const date = new Date(isoDate);

  const optionsDate = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const optionsTime = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true, // This ensures the time is in 12-hour format with AM/PM
  };

  const formattedDate = date.toLocaleDateString('en-US', optionsDate);
  const formattedTime = date.toLocaleTimeString('en-US', optionsTime);

  return `${formattedDate}`;
  // return `${formattedDate} at ${formattedTime}`;
};

  return (
    <Container size='full'>
      <div className='user-profile'>
        <div className='left-part'>
          <div className='box user-details'>
            <div className='title'>User Details</div>
            <div className='content'>
              <div className='user-icon'><img src='https://bigbull9exch.com/assets/img/user-profile/user-profile.png' /></div>
              <div className='username'>{userData && userData.user.userName}</div>
              <div className="user-info">
                <Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                    <path d="M20 16C18.8 16 17.5 15.8 16.4 15.4H16.1C15.8 15.4 15.6 15.5 15.4 15.7L13.2 17.9C10.4 16.4 8 14.1 6.6 11.3L8.8 9.1C9.1 8.8 9.2 8.4 9 8.1C8.7 7 8.5 5.7 8.5 4.5C8.5 4 8 3.5 7.5 3.5H4C3.5 3.5 3 4 3 4.5C3 13.9 10.6 21.5 20 21.5C20.5 21.5 21 21 21 20.5V17C21 16.5 20.5 16 20 16ZM5 5.5H6.5C6.6 6.4 6.8 7.3 7 8.1L5.8 9.3C5.4 8.1 5.1 6.8 5 5.5ZM19 19.5C17.7 19.4 16.4 19.1 15.2 18.7L16.4 17.5C17.2 17.7 18.1 17.9 19 17.9V19.5Z" fill="#182EA6"></path>
                  </svg>
                  {userData && maskContact(userData.user.contact)}
                </Link>
                <Link>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none" className="email_icon">
                    <path d="M7 9.5L10.75 12.5C11.1047 12.784 11.5456 12.9388 12 12.9388C12.4544 12.9388 12.8953 12.784 13.25 12.5L17 9.5M21 17.5V7.5C21 6.96957 20.7893 6.46086 20.4142 6.08579C20.0391 5.71071 19.5304 5.5 19 5.5H5C4.46957 5.5 3.96086 5.71071 3.58579 6.08579C3.21071 6.46086 3 6.96957 3 7.5V17.5C3 18.0304 3.21071 18.5391 3.58579 18.9142C3.96086 19.2893 4.46957 19.5 5 19.5H19C19.5304 19.5 20.0391 19.2893 20.4142 18.9142C20.7893 18.5391 21 18.0304 21 17.5Z" stroke="#182EA6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>
                  {userData && maskEmail(userData.user.email)}
                </Link>

              </div>
              <div className='change-psw-link'>
                <Link onClick={handleShow}>Change Password </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='right-part'>
          <div className='box statitics'>
            <div className='title'>Statitics</div>
            <div className='content'>
              <div className='profit-box-row'>
                <div className='profit-box'>
                  <p>Today profit <strong>{userData && userData.userProfileContent.totalReturns}</strong></p>
                </div><div className='profit-box'>
                  <p>Today profit in Sports <strong>{userData && userData.userProfileContent.SPORTPROFIT}</strong></p>
                </div><div className='profit-box'>
                  <p>Today profit in Casino <strong>{userData && userData.userProfileContent.CASINOPROFIT}</strong></p>
                </div>
              </div>
              <div className='profit-box-row'>
                <div className='profit-box'>
                  <p>Today total deposit <strong>₹ {userData && userData.todaytotalDeposite}</strong></p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      {JSON.parse(localStorage.getItem("isB2c") || "false") ? <> 
      <div className='user-profile full-w'>
        <div className='right-part'>
          <div className='box statitics'>
            <div className='title'>Earn bonus by referring friends. Refer someone and both you and your friend will earn. The bonus will be unlocked once your friend place a bet or complete conditions of platform.</div>
            <div className='content'>
              <div className='profit-box-row'>
                <div className='profit-box'>
                  <p>Total Bonus Earned <strong>{bonusData && bonusData.bonus}</strong></p>
                </div><div className='profit-box'>
                  <p>Completed Referrals <strong>{bonusData && bonusData.completereferal}</strong></p>
                </div><div className='profit-box'>
                  <p>Accepted Referrals <strong>{bonusData && bonusData.acceptedreferal}</strong></p>
                </div>
              </div>
              <div className='profit-box-row'>
                <div className='profit-box'>
                  <p>Your unique referral link: <strong>{userData && userData.user.referalcode} : <span className="copy-icon" onClick={handleCopy}></span></strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='user-profile full-w'>
        <div className='right-part'>
        <div className="account-table"> 
              <table border="1" cellPadding="10" cellSpacing="0">
              <thead>
                <tr>
                  <th className="game">Date</th>
                  <th>Friend User name</th>
                  <th>Reward Earned</th>
                </tr>
              </thead>
              <tbody>
                {bonusData &&
                  bonusData.referaltable &&
                  bonusData.referaltable.length > 0 ? (
                    bonusData.referaltable.map((item, index) => (
                    <tr
                      key={index}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{formatDatefromAPI(item.createdDate)}</td>
                      <td>{item.refereeUserName}</td>
                      <td>{item.bonusAmount}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
              </table>
            </div>
        </div>
      </div>
       </> :''}
      
      <ChangePasswordPopup isopen={changePassword} handleClose={handleClose} />
    </Container>

  )
}
