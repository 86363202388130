import { useEffect, useState, useCallback,useRef } from 'react';
import io from 'socket.io-client';

const SOCKET_SERVER_URL = 'devapi.supermaster.live/';
const SOCKET_SERVER_URL_LIVE = 'api.supermaster.live/';
// const token = localStorage.getItem('authToken');  // Get token from localStorage

const Websoket = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [receivedMessages, setReceivedMessages] = useState({});
  const [receivedlottrey, setReceivedlottrey] = useState({});
  const [receivedMatchoddsHG, setReceivedMatchoddsHG] = useState({});
  const [receivedlottreybookDetails, setReceivedlottreybookDetails] = useState();
  const [receivedMatchodds, setReceivedMatchodds] = useState({});
  const [receivedToken, setReceivedToken] = useState({});
  const [receivedMMOpenBets, setReceivedMMOpenBets] = useState([]);
  const [receivedmarketIdbookDetailsMM, setReceivedmarketIdbookDetailsMM] = useState([]);
  const [receivedMultiMarket, setReceivedMultiMarket] = useState([]);
  const [receivedMultiMarketUpdates, setReceivedMultiMarketUpdates] = useState([]);
  const [receivedBookmaker, setReceivedBookmaker] = useState({});
  const [receivedOtherBookmaker, setReceivedOtherBookmaker] = useState({});
  const [receivedMnbookmaker, setReceivedMnbookmaker] = useState({});
  const [receivedFancysession, setReceivedFancysession] = useState({});
  const [receivedFancywpMarket, setReceivedFancywpMarket] = useState({});
  const [receivedFancyOddEven, setReceivedFancyOddEven] = useState({});
  const [receivedFancyballByBall, setReceivedFancyballByBall] = useState({});
  const [receivedFancyWiningSeat, setReceivedFancyWiningSeat] = useState({});
  const [receivedFancyOnlyOver, setReceivedFancyOnlyOver] = useState({});
  const [receivedBetlistData, setreceivedBetlistData] = useState({});
  const [receivedwalletData, setreceivedwalletData] = useState({});
  const [receivedBookData, setreceivedBookData] = useState({});
  const [receivedAllBookData, setreceivedAllBookData] = useState({});
  const [receivedcashoutBm, setreceivedcashoutBm] = useState({});
  const [receivedcashoutMO, setreceivedcashoutMO] = useState({});
  const [receivedBookFancy, setreceivedBookFancy] = useState({});
  const [receivedToss, setReceivedToss] = useState({});
  const [receivedunderOve0, setReceivedunderOve0] = useState({});
  const [receivedunderOve1, setReceivedunderOve1] = useState({});
  const [receivedunderOve2, setReceivedunderOve2] = useState({});
  const [receivedunderOve3, setReceivedunderOve3] = useState({});
  const [receivedMoArray, setReceivedMoArray] = useState({});
  const [getAllBets, setAllBets] = useState();
  const [receivedunderOvebook, setReceivedunderOvebook] = useState({});
  const [receivedMinMax, setReceivedMinMax] = useState({});


  const [socket, setSocket] = useState(null);
  const [printmsg, setprintmsg] = useState();
  const socketRef = useRef(null);

  const getURL =() => {
    if(process.env.REACT_APP_ENV === 'development'){
      return SOCKET_SERVER_URL;
    }else{
      return SOCKET_SERVER_URL_LIVE;
    }
  }
  // let newSocket
  useEffect(() => {
    // if (!socketRef.current) {
   
      const  newSocket = io(getURL(), {
        transports: ['websocket'],
        // reconnectionAttempts: 5, // Optional: limit reconnection attempts
        // reconnectionDelay: 1000, // Optional: delay between reconnection attempts
      });
    // }
      socketRef.current = newSocket
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('Websocket Connected');
      setIsConnected(true);
    });

    newSocket.on('connect_error', (err) => {
      console.error('Connection error:', err.message);
      setIsConnected(false);
    });

    newSocket.on('disconnect', () => {
      console.log('Socket disconnected');
      setIsConnected(false);
    });

    // Listen for messages dynamically
    const handleIncomingMessage = (event, callback) => {
      newSocket.on(event, (data) => {
        if(event === "inplaymatchdetails"){
          console.log("Socket:---- inplaymatchdetails ==== ",data);
          // console.log(data?.data?.thatMatchMarkets?.matchOdds, 'datadatadatadatadatadatadatadatadatadatadatadatadatadatadata');
          setReceivedMessages(data);
          let aarray = data?.data?.lottretMarket
          // console.log(aarray, 'aarrayaarrayaarrayaarray'); 
          
          setReceivedMoArray(aarray)
        }else if (event === 'lottretMarketID'){
          if(data.lottretMarket){
            setReceivedlottrey(data.lottretMarket)
          }
        }else if(event === "matchOddsMarketIds"){
          setReceivedMatchodds(data);
          // console.log(data,"datamatchOddsMarketIds");
          
        }else if(event === "getmarketminmaxdata"){
          setReceivedMinMax(data);
          // console.log(data,"datagetmarketminmaxdata");
        }else if (event === "marketIdbookDetailsByEventId"){   
          // console.log('marketIdbookDetails',data);
          setreceivedAllBookData(data)
        }else if(event === "createToken"){
          setReceivedToken(data);
        }else if(event === "AllOPenBets"){
          setAllBets(data);
        }else if(event === "matchOddsMarketIdsHG"){
          setReceivedMatchoddsHG(data);
        }else if(event === "lottreybookDetails"){
          // console.log(data,"datadata");
          
          setReceivedlottreybookDetails(data);
        }else if(event === "winningSeat"){
          setReceivedFancyWiningSeat(data);
        }else if(event === "multiMarketOpenBets"){
          setReceivedMMOpenBets(data);
        }else if(event === "marketIdbookDetailsMultiMarket"){
          setReceivedmarketIdbookDetailsMM(data);
        }else if(event === "multiMartketOddsUpdate"){
          setReceivedMultiMarket(data);
        }else if(event === "multimarketUpdate"){
          setReceivedMultiMarketUpdates(data);
        }else if(event === "bookmakerMarketIds"){
          setReceivedBookmaker(data);
        }else if (event === 'otherBookmakerMarketIds'){
          setReceivedOtherBookmaker(data)
        }else if(event === "minibookmakerMarketIds"){
          setReceivedMnbookmaker(data);
        }else if(event === "sessionMarketIds"){
          setReceivedFancysession(data);
          console.log('sessionMarketIds',data);
        }else if(event === "wpMarketIds"){
          setReceivedFancywpMarket(data);
        }else if(event === "oddEvenMarketIds"){
          setReceivedFancyOddEven(data);
        }else if(event === "ballbyballmaketIds"){
          setReceivedFancyballByBall(data);
        }else if(event === "onlyOverMarketIds"){
          setReceivedFancyOnlyOver(data);
        }else if(event === "getbetlistdata"){
          setreceivedBetlistData(data);
        }else if(event === "userLoginBalance"){
          setreceivedwalletData(data)
        }else if (event === "marketIdbookDetails"){   
          // console.log('marketIdbookDetails',data);
          setreceivedBookData(data)
        }else if(event === "cashOOutBm"){
          setreceivedcashoutBm(data)
        }else if(event === "cashOOut"){
          setreceivedcashoutMO(data)
        }else if(event === "getFancyBookDATAuserSide"){
          setreceivedBookFancy(data)
        } else if(event === "tossMarketIds"){
          setReceivedToss(data)
        } else if(event === "overundermarketId0"){
          setReceivedunderOve0(data)
        } else if(event === "overundermarketId1"){
          setReceivedunderOve1(data)
        } else if(event === "overundermarketId2"){
          setReceivedunderOve2(data)
        } else if(event === "overundermarketId3"){
          setReceivedunderOve3(data)
        } else if(event === "marketIdbookDetailsUnderOver"){
          setReceivedunderOvebook(data)
        } 
      });
    };

    setprintmsg(handleIncomingMessage)


    // Example event listeners
    handleIncomingMessage('createToken');
    handleIncomingMessage('marketIdbookDetailsByEventId');
    handleIncomingMessage('getmarketminmaxdata');
    handleIncomingMessage('inplaymatchdetails');
    handleIncomingMessage('lottreybookDetails');
    handleIncomingMessage('lottretMarketID');
    handleIncomingMessage('matchOddsMarketIds');
    handleIncomingMessage('matchOddsMarketIdsHG');
    handleIncomingMessage('multiMarketOpenBets');
    handleIncomingMessage('ballbyballmaketIds');
    handleIncomingMessage('winningSeat');
    handleIncomingMessage('AllOPenBets');
    handleIncomingMessage('marketIdbookDetailsMultiMarket');
    handleIncomingMessage('multiMartketOddsUpdate');
    handleIncomingMessage('multimarketUpdate');
    handleIncomingMessage('bookmakerMarketIds');
    handleIncomingMessage('otherBookmakerMarketIds');
    handleIncomingMessage('minibookmakerMarketIds');
    handleIncomingMessage('sessionMarketIds');
    handleIncomingMessage('wpMarketIds');
    handleIncomingMessage('oddEvenMarketIds');
    handleIncomingMessage('onlyOverMarketIds');
    handleIncomingMessage('getbetlistdata');
    handleIncomingMessage('userLoginBalance');
    handleIncomingMessage('marketIdbookDetails');
    handleIncomingMessage('cashOOutBm');
    handleIncomingMessage('cashOOut');
    handleIncomingMessage('getFancyBookDATAuserSide');
    handleIncomingMessage('tossMarketIds');
    handleIncomingMessage('overundermarketId0');
    handleIncomingMessage('overundermarketId1');
    handleIncomingMessage('overundermarketId2');
    handleIncomingMessage('overundermarketId3');
    handleIncomingMessage('marketIdbookDetailsUnderOver');

    return () => {
      if (newSocket) newSocket.disconnect();
    };
  }, []);

  // Function to send messages to the server
  const sendMessage = useCallback((event, message) => {
    if (socket) {
      // if (event === 'createToken') {
        // console.log(`Sokect send ---- ${event}`, message);        
      // }
      socket.emit(`${event}`, message);
    }
  }, [socket]);
  const clearCashout = useCallback((event, message) => {
    setreceivedcashoutMO({});
    setreceivedcashoutBm({});
  }, [socket]);


  return { isConnected, receivedMinMax, receivedToken, receivedlottreybookDetails , receivedFancyWiningSeat ,receivedMessages, receivedlottrey,receivedToss, receivedMatchodds, receivedMatchoddsHG, receivedOtherBookmaker,receivedBookmaker, receivedMnbookmaker, receivedFancysession, receivedFancywpMarket, receivedFancyOddEven, receivedFancyOnlyOver, receivedBetlistData,receivedwalletData,receivedBookData,receivedAllBookData,receivedcashoutBm,receivedcashoutMO,receivedBookFancy,receivedunderOve0,receivedunderOve1,receivedunderOve2,receivedunderOve3,receivedunderOvebook,receivedMultiMarket,receivedMultiMarketUpdates,receivedMMOpenBets, receivedmarketIdbookDetailsMM,receivedFancyballByBall,getAllBets,receivedMoArray ,sendMessage,clearCashout};
};

export default Websoket;
