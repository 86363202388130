const config = {
    WhiteLable: window.location.hostname,
    TabCricket:'cricket',
    TabFootball:'football',
    TabTennis:'tennis',
    TabKabaddi:'kabaddi',
    TabElection:'election',
    TabHorseRacing:'horseracing',
    TabGreyhoundRacing:'greyhound',
    lay:"LAY",
    back:"BACK",
    fancy:"FANCY",
    isNotfancy:"NO-FANCY",
    updateStackMsg:"Stack Update Successfully",
    stackPopupHeading:"SET BUTTON STAKE",
    MATCH_ODDS:"MATCH_ODDS",
    BOOKMAKER:"BOOKMAKER",
    BOOKMAKER:"BOOKMAKER",
    TOSS:"TOSS",
    FANCY:"FANCY",
    UPCOMING:"UPCOMING",
    INPLAY:"IN-PLAY",
    DATA:{},
    typeSession:'OVERS',
    typeOnlyOver:'SINGLE_OVER',
    typeWPmarket:'BATSMAN',
    typeOddEven:'ODD_EVEN',
    typeWinningSeat:'WINNING_SEATS',
    typeBallbyBall:'BALL_BY_BALL',
    getDateFormated :'dd-MM-yyyy',
    defualtGame : "Mac88 Gaming",
    Topic_Match_Odds:process.env.REACT_APP_ENV === 'development' ? '/topic/diamond_match_odds_update/':'/topic/betfair_match_odds_update/',
    Topic_Bookmaker:process.env.REACT_APP_ENV === 'development' ? '/topic/diamond_bm_update/':'/topic/tommy_bm_update/',
    Topic_Fancy:process.env.REACT_APP_ENV === 'development' ? '/topic/diamond_fancy_update/':'/topic/tommy_fancy_update/',
    fancyMinMaxTime:15000,
    matchOddsMinMaxTime:15000,
    bookmakerMinMaxTime:15000,
    maintanaceTime:15000
  };
  
  export default config;
  