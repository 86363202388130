import React, {useContext, useEffect, useMemo, useState } from 'react'
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import { PopupContext } from '../../LoginPopup/LoginPopupContext.js';
import ExchanngeinfoPopup from '../ExchanngeinfoPopup.js';
import useSocket from '../../../Websoket.js';
import betslipclosearrow from '../../../assets/images/betslip-close-arrow.png';
import FancyBookPopup from './FancyBookPopup.js';


function Fancy({ eventAccess, getFancy, getfancyClick, receivedBetlistData ,lottreyArray }) {
    // const [tab, setTab] = useState('all');
    const [grulesPopup, setGrulesPopup] = useState();
    const { isConnected, sendMessage, receivedlottrey,receivedlottreybookDetails } = useSocket();
    const [firstTimeCall, setFirstTimeCall] = useState(false)
    const [lottreyArrayUSE, setlottreyArrayUSE] = useState([])
    const [slideToggle, setSlideToggle] = useState(false)
    const [bklyClassValue, setBklyClass] = useState();
    const [oddsValue, setoddsValue] = useState('');
    const { stackArray, openPopup, loggedIn, isLoggedOut, direactsecId, setSecIds, clearSecId, betSlipOpen, closeBetSlip, openBetSlip,isBetLoader, loaderCounter } = useContext(PopupContext);
    const [priceGet, setPriceGet] = useState(0);
    const [Beton, setBeton] = useState();
    const [secID, setSecId] = useState();
    const [betLoader, setBetLoader] = useState(false);
    const [stack, setStack] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
    const [plusMinusValue, setplusMinusValue] = useState();

    const [fancyBookPopup, setFancyBookPopup] = useState()

    const formatNumberinStack = (num) => {
      if (num >= 1000000000) {
        return (num / 1000000000) + 'B'; // Billion
      } else if (num >= 1000000) {
        return (num / 1000000) + 'M'; // Million
      } else if (num >= 1000) {
        return (num / 1000) + 'K'; // Thousand
      } else {
        return num; // Less than 1000
      }
    };

    const isOneClicked = sessionStorage.getItem('isOneClicked')
    let oneClickValue
    if (isOneClicked == 'true') {
      oneClickValue = sessionStorage.getItem('oneClickAmount')
    }

    const gruleshandleClose = ()=>{
        setGrulesPopup(false);
    }
    const grulesHandle = ()=>{
        setGrulesPopup(true);
    }
    const memoizedLottreyArray1 = useMemo(() => receivedlottrey, [receivedlottrey]);

    useEffect(() => {
    //   console.log(memoizedLottreyArray1,"receivedlottreyreceivedlottreyreceivedlottrey");
    //   console.log(receivedlottrey?.finalResult?.find(item => item.marketName === item.marketName)?.max_stake,"max_stakemax_stakemax_stake");
    //   console.log(receivedlottrey?.finalResult,"max_stakemax_stakemax_stake");
    setlottreyArrayUSE(memoizedLottreyArray1);
    //   setlottreyArrayUSE(receivedlottrey)
    }, [memoizedLottreyArray1])

    const memoizedLottreyArray = useMemo(() => lottreyArray, [lottreyArray]);

    useEffect(() => {
    //   console.log(lottreyArray,"lottreyArraylottreyArraylottreyArray");
    setlottreyArrayUSE(memoizedLottreyArray);
    }, [memoizedLottreyArray])

    useEffect(() => {
      let intervalId;
      if (isConnected && lottreyArray) {
          let matchdata = null;
          let userData = sessionStorage.getItem('userId')
          if(userData){
              matchdata =  {
                  "eventId": eventAccess?.matchData?.eventId,
                  "userId": userData
              }
          }else{
              matchdata =  {
                  "eventId": eventAccess?.matchData?.eventId
              }
          }
          if (!firstTimeCall) {
              setFirstTimeCall(true)
              sendMessage('lottretMarketID', matchdata);
          }
          intervalId = setInterval(() => {
              sendMessage('lottretMarketID', matchdata);
          }, 5000);
      }
      return () => {
          if (intervalId) {
              clearInterval(intervalId);
          }
      };
    }, [isConnected, lottreyArray, sendMessage]);


    const toggleHandle = (slideId, bklyClass, event, runnerName,price,runnerId) => {
      console.log('spanText spanText spanText', price);
      setSlideToggle(slideToggle === slideId ? slideId : slideId);
      setBklyClass(bklyClass);
      setoddsValue(price);
      setSecIds(slideId);
      setSecId(runnerId);
      openBetSlip();
      setPriceGet('')
      setBeton(runnerName)
    }

    const getBetSlipClasss = (item) => {
      if (direactsecId === item.marketId) {
          if(item.status !== 'OPEN'){
              clearSecId()
              return 'betslip-wraper'
          }else{
              return 'betslip-wraper open'
          }
      }
      return 'betslip-wraper';
    };

    const handleShow = (marketId,eventId) => {
        setFancyBookPopup(true)
        let userinfo = sessionStorage.getItem('userinfo');
        if (userinfo && marketId) {
            userinfo = JSON.parse(userinfo);
            console.log(JSON.stringify({ id: marketId, user: userinfo }),"-------------------------------");

            sendMessage('lottreybookDetails', { marketId: marketId,eventId:eventId, user: userinfo });
        }
    }

    const handleClose = () => {
        setFancyBookPopup(false)
    }

    useEffect(() => {
        console.log(receivedlottreybookDetails,"receivedlottreybookDetailsreceivedlottreybookDetails");

    },[receivedlottreybookDetails])

    useEffect(() => {
        try{
            setStack(JSON.parse(stackArray));
            if(!isBetLoader){
                handlecloseSlip();
            }
        }catch(err){
            console.log(err, 'errerrerrerr');
            setStack(stackArray)
            if(!isBetLoader){
                handlecloseSlip();
            }
        }
      }, [isBetLoader, loaderCounter,stackArray]);

    const handlecloseSlip = () => {
        setSlideToggle(false);
        clearSecId();
        closeBetSlip();
        setPriceGet('')
    }

    const addValue = (newValue) => {
      setplusMinusValue(newValue)
      if (priceGet !== undefined) {
          setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
      } else {
          setPriceGet(newValue);
      }
    };

    const addAllValue = (newValue) => {
      setplusMinusValue(newValue)
          setPriceGet(newValue);
    };

    const convertToOriginalValue = (shorthand) => {
      // Ensure shorthand is a string
      if (typeof shorthand !== 'string') {
          return shorthand; // Return the value as is if it's not a string
      }

      const value = parseFloat(shorthand); // Extract numeric part
      if (shorthand.toUpperCase().endsWith('K')) {
          return value * 1_000; // Multiply by 1,000 for 'K'
      } else if (shorthand.toUpperCase().endsWith('M')) {
          return value * 1_000_000; // Multiply by 1,000,000 for 'M'
      }
      return value; // Return the numeric value if no suffix
    };

    const allValue = () => {
      let userinfo = sessionStorage.getItem('balance');
      if (userinfo !== null) {
          let data = JSON.parse(userinfo);
          return (data.availableBalance - data.totalExposure).toFixed(2);
      }else{
          return 0;
      }
    };

    const subtractValue = () => {
      const valueToSubtract = plusMinusValue ? plusMinusValue : 100
      setPriceGet(prevPrice => {
          const currentPrice = prevPrice ? Number(prevPrice) : 0;
          const newPrice = currentPrice - valueToSubtract;
          return newPrice < 0 ? 0 : newPrice;
      });
    };

    const PlusValue = () => {
      const newValue = plusMinusValue ? plusMinusValue : 100


      if (priceGet !== undefined) {
          setPriceGet(prevPrice => Number(prevPrice) + Number(newValue));
      } else {
          setPriceGet(newValue);
      }
    };

    const incrementValue = (increment) => {
      setPriceGet(prevPrice => {
          const currentPrice = prevPrice ? Number(prevPrice) : 0;
          return currentPrice + increment;
      });
    };

    const removeLastDigit = () => {
      setPriceGet(prevPrice => {
          if (prevPrice !== undefined && prevPrice !== null) {
              const newPrice = String(prevPrice).slice(0, -1);
              return newPrice === '' ? 0 : Number(newPrice);
          }
          return 0;
      });
    };

    const onClickFunction = (slideId, bklyClass, event, runnerName,price,runnerId) => {
        event.persist();
        setBetLoader(true);
        setSecIds(slideId);
        setSecId(runnerId);
        setSlideToggle(slideToggle === slideId ? slideId : slideId);
        setBklyClass(bklyClass);
        setoddsValue(price);
        setPriceGet(oneClickValue)
        // console.log(JSON.stringify({ eventid:eventAccess?.matchData?.eventId, marketId: slideId, stake: oneClickValue, betType:bklyClass, selectionName: runnerName, oddsValue: price ,secId: runnerId}),"1121212121212121212121212");

        getfancyClick({ eventid:eventAccess?.matchData?.eventId, marketId: slideId, stake: oneClickValue, bklyClassValue:bklyClass, selectionName: runnerName, oddsValue: price ,secId: runnerId})
    }



    return (
            <>
             {lottreyArrayUSE.length > 0?
            <div className='sport-all-data inner-match-list lottery-list'>
              <div className='match-wrapper'>
                <Accordion transition transitionTimeout={350}>
                <AccordionItem initialEntered
                header={
                    <div className='heading'>
                    <div className='heading-text sport-name'>
                    Lottery Market <span className='rulespopup-btn' onClick={grulesHandle}><i className="fa-solid fa-circle-info"></i></span>
                    </div>
                </div>}>
                <div className='match-data'>
                <div className='match-list'>
                {lottreyArrayUSE.length > 0?
                    lottreyArrayUSE?.map((item) => (
                    <div className='data-with-betslip'>
                      <div className='match-info-row'>
                        <div className='match-name'>{item.marketName}
                            {
                              receivedBetlistData && receivedBetlistData.bets && receivedBetlistData.bets.filter(item2 => item2.marketId === item.marketId).length > 0 ? (
                                <button className="btn fancy-book-btn" onClick={() => handleShow(item.marketId,eventAccess?.matchData?.eventId)}>Book</button>
                              ) : null
                            }
                        </div>
                        <div className='market-limit'>
                          <span>Min : {item?.min_stake}, </span>
                          <span>Max : {item?.max_stake}</span>
                        </div>
                        <div className="match-odds">
                        {item?.isSuspend ? <div className="suspended">SUSPENDED</div> :''}
                            {
                              item?.runners?.map((item1) => (
                                <>
                                  <div className="box" onClick={(event) => {
                                      if(!isBetLoader){
                                      if (isOneClicked === "true") {
                                          onClickFunction(item.marketId, 'back', event, item1.runnerName,item1.backPrices[0].price,item1.runnerId);
                                      } else {
                                          toggleHandle(item.marketId, 'back', event, item1.runnerName,item1.backPrices[0].price,item1.runnerId);
                                      }
                                      }
                                  }}>
                                    <span className={`blue ${item1?.max_odd < item1?.yesValue ? 'disable' : ''}`}>
                                      <strong>{item1.runnerName}</strong>
                                    </span>
                                  </div>
                                </>
                              ))
                            }
                        </div>
                      </div>
                       <div id={direactsecId} className={getBetSlipClasss(item)}>
                        <div className={`betslip ${direactsecId === item.marketId ? 'active' : ''} ${bklyClassValue}`}>
                            <div className="close-btn" onClick={handlecloseSlip}>×</div>
                            <div className="betslip-top">
                                <div className="bet-selection-name" > {Beton} - WINNING ODDS </div>
                                <div className="bet-profit"></div>
                                <div className="betslip-odds"> <strong>Odds</strong> <span className="blue-text">{oddsValue}</span> </div>
                                <div className="betslip-stake">
                                    <strong>Stake</strong>
                                    <div className="input-group">
                                        <span className="minus" onClick={() => {
                                            setPriceGet((prevPrice) => (Number(prevPrice) >= 100 ? Number(prevPrice) - 100 : 0));
                                        }}>-</span>
                                        <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d*$/.test(value)) {
                                            setPriceGet(value);
                                            }
                                        }}  aria-label="Last name" autoFocus="" maxLength="8" />
                                        <span className="plus" onClick={() => { setPriceGet((prevPrice) => Number(prevPrice) + 100) }}>+</span>
                                    </div>
                                </div>
                            </div>
                            <div className="betslip-placebutton">
                                <div className="price-btns">
                                {Array.isArray(stack) && stack.map((item,index) => (
                                            <span key={index} id={`${item}`} onClick={(event) => addValue(`${item}`)}>{formatNumberinStack(item)}</span>
                                        )) }
                                    <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedlottrey?.find(it => it.marketName === item.marketName).max_stake)}`,event)}>MAX</span>
                                    <span id="1000" className="MAX" onClick={(event)=>addAllValue(`${allValue()}`,event)}>All</span>
                                </div>
                                <div className="betplace-btn">
                                    {loggedIn || isLoggedIn ? <button className="place-bet" onClick={() => {
                                            { setBetLoader(true);
                                            getfancyClick({ eventid:eventAccess?.matchData?.eventId, marketId: item.marketId, stake: priceGet, bklyClassValue, selectionName: Beton, oddsValue: oddsValue,secId: secID})}
                                    }}>Place Bet</button> : <button className="login-btn" onClick={openPopup}>Login to continue</button>}
                                </div>
                            </div>
                        </div>
                        <div className={`betslip_mobileview ${direactsecId === item.marketId ? 'active' : ''} ${bklyClassValue}`}>
                            <div className="close-btn" onClick={handlecloseSlip}>×</div>
                            <table className="eventdetails">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className='plus-minus-wraper'>
                                                <div className='plus-minus'>
                                                    <span>-</span>
                                                    <input type="text" value={oddsValue}/>
                                                    <span>+</span>
                                                </div>
                                                <div className='plus-minus'>
                                                    <span onClick={() => subtractValue()}>-</span>
                                                    <input type="text" value={priceGet} className="set-stake-form-input" onChange={(e) => {
                                                        const value = e.target.value;
                                                        if (/^\d*$/.test(value)) {
                                                        setPriceGet(value);
                                                        }
                                                    }}  aria-label="Last name" autoFocus="" maxLength="8" />
                                                    <span onClick={() => PlusValue()} >+</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="11" className='mbet-slip'>
                                            <div className="mbet-slip-wraper">
                                            {Array.isArray(stack) && stack.map((item,index) => (
                                                    <div key={index} id={`${item}`} className="odds-value" onClick={(event) => addValue(`${item}`)}>{formatNumberinStack(item)}</div>
                                                )) }
                                                <div className="odds-value max" onClick={(event)=>addAllValue(`${convertToOriginalValue(receivedlottrey?.find(item => item.marketName === item.marketName).max_stake)}`,event)} id="10000">MAX</div>
                                                <div className="odds-value all" onClick={(event)=>addAllValue(`${allValue()}`,event)} id="98941.80">ALL IN</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="numbering">
                                        <td colSpan="11">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td><span onClick={() => incrementValue('1')} id="1">1</span></td>
                                                        <td><span onClick={() => incrementValue('2')} id="2">2</span></td>
                                                        <td><span onClick={() => incrementValue('3')} id="3">3</span></td>
                                                        <td><span onClick={() => incrementValue('4')} id="4">4</span></td>
                                                        <td><span onClick={() => incrementValue('5')} id="5">5</span></td>
                                                        <td><span onClick={() => incrementValue('6')} id="6">6</span></td>
                                                        <td rowSpan="2"><span className="back-span" onClick={() => removeLastDigit()}><img alt="" src={betslipclosearrow} /></span></td>
                                                    </tr>
                                                    <tr>
                                                        <td><span onClick={() => incrementValue('7')} id="7">7</span></td>
                                                        <td><span onClick={() => incrementValue('8')} id="8">8</span></td>
                                                        <td><span onClick={() => incrementValue('9')} id="9">9</span></td>
                                                        <td><span onClick={() => incrementValue('0')} id="0">0</span></td>
                                                        <td><span onClick={() => incrementValue('00')} id="double0">00</span></td>
                                                        {/* <td><span onClick={() => incrementValue('000')} id="dot">000</span></td> */}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr className="bet-btns">
                                        <td colSpan="11">
                                            <div className="btn-wraper">
                                                <button className="btn cancel-bet">CANCEL</button>
                                                {loggedIn || isLoggedIn ? <button className="btn place-bet" onClick={() => {
                                                    {
                                                        setBetLoader(true);
                                                        getfancyClick({ eventid:eventAccess?.matchData?.eventId, marketId: item.marketId, stake: priceGet, bklyClassValue, selectionName: Beton, oddsValue: oddsValue,secId: secID})
                                                    }
                                                }}>Place Bet</button> : <button className="btn place-bet" onClick={openPopup}>Login to continue</button>}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        {isBetLoader ?
                            <div className="loader-sec loading">
                                Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{String(loaderCounter).padStart(2, '0')}</span><span className='loader'></span></div>
                            </div> : ''}

                        </div>

                </div>

                )) : ''}
                                    </div>
                                    </div>
                </AccordionItem>
                </Accordion>
              </div>
            </div>
            :''}

          <FancyBookPopup isopen={fancyBookPopup} handleClose={handleClose} receivedBookFancy={receivedlottreybookDetails} />
        <ExchanngeinfoPopup isOpen={grulesPopup} handleClose={gruleshandleClose} />
        </>
    )
}

export default Fancy