import React, { useContext, useEffect, useRef, useState } from 'react';
import './allsportdata.css';

import { Link, useLocation } from 'react-router-dom';
import { PopupContext } from '../LoginPopup/LoginPopupContext';
import OddsFeedComponenttt from './OddsFeedComponenttt.js';

function Allsportdata({ matchData,selectedTypes }) {
    const [updateMatchOdds, setStateValue] = useState();
    const [types, setTypes] = useState();
    const { exchangeData,setExchangeData } = useContext(PopupContext);
    const initialized = useRef(false);
    const [multimarketArrrrr, setMultimarketArrrrr] = useState([])
    const location = useLocation();

    useEffect(() => {
        setStateValue(matchData);
        setTypes(selectedTypes);        
        // if (Array.isArray(exchangeData) && exchangeData.length > 0) {
        //     exchangeData.forEach((exchnageItem, exchnageIndex) => {
        //         console.log(exchnageItem.eventName, '---', exchnageItem.sportId);
        //     });
        // }
    }, [matchData,selectedTypes,exchangeData,location]);

    const validation = (price) => {
        if(price &&
            price !== '-' &&
            price !== 0 &&
            price <= 1000.00){
                return true
            }else{
                return false
            }
    }

    useEffect(() => {
        if (!initialized.current) {
          initialized.current = true;
          if (sessionStorage.getItem('userId')) {
            callAPI();
          }
        }
        // console.log(receivedMultiMarket,"------receivedMultiMarket");

    }, []);

    const callAPI = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/getmultimarket`, requestOptions)
          .then(async (response) => {
            let paresData = await response.json();
            setMultimarketArrrrr(paresData.muliMarketArray);
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const dateFormet = (date)=>{
        return new Date(date).toLocaleString('en-US', {
            month: 'short',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        })
    }

    const renderData = (gameItem) => {
        let lastSeries = null;
        // console.log(gameItem.matchData,"---gameItem.matchData");
        
        return (gameItem && gameItem.matchData && gameItem.matchData.length > 0 ?
            gameItem.matchData.map((matchItem, matchindex) => {
            let currentCompetitionName
            let shouldShow
            if(!["7","4339"].includes(matchItem.match.sportId)){
                currentCompetitionName = matchItem.match.competitionName;
                shouldShow = lastSeries !== currentCompetitionName;
                lastSeries = currentCompetitionName;
            }else{
                currentCompetitionName = matchItem.match.competitionName.split("-")[0];
                shouldShow = lastSeries !== currentCompetitionName;
                lastSeries = currentCompetitionName;
            }

            // Find matching exchange item
            let updatedExchangeItem = null;
            if (Array.isArray(exchangeData) && exchangeData.length > 0) {
                updatedExchangeItem = exchangeData.find(
                    (exchangeItem) => exchangeItem.eventId === matchItem.match.eventId
                );
            }

            // Update matchItem if a match is found
            if (updatedExchangeItem) {
                matchItem.match.eventName = updatedExchangeItem.eventName;
                matchItem.match.sportId = updatedExchangeItem.sportId;
                // matchItem.match.status = updatedExchangeItem.status;
                if (matchItem != null) {
                    if(Array.isArray(matchItem.matchodd) && matchItem.matchodd.length > 0 ){
                        if(matchItem.matchodd[0].runners !== undefined ){
                            if (Array.isArray(matchItem.matchodd[0].runners)) {
                                const updatedRunners = updatedExchangeItem.markets.matchOdds[0]?.runners;

                                if (updatedRunners) {
                                    if (matchItem.matchodd[0].runners.length === 1) {
                                        // Check if updatedExchangeItem.matchodd.runners[0] is defined
                                        if (updatedRunners[0]?.layPrices && updatedRunners[0]?.backPrices) {
                                            matchItem.matchodd[0].runners[0].layPrices = updatedRunners[0].layPrices;
                                            matchItem.matchodd[0].runners[0].backPrices = updatedRunners[0].backPrices;
                                        }
                                    } else if (matchItem.matchodd[0].runners.length === 2) {
                                        // Check if updatedExchangeItem.matchodd.runners[0] and [1] are defined
                                        if (updatedRunners[0]?.layPrices && updatedRunners[0]?.backPrices) {
                                            matchItem.matchodd[0].runners[0].layPrices = updatedRunners[0].layPrices;
                                            matchItem.matchodd[0].runners[0].backPrices = updatedRunners[0].backPrices;
                                        }
                                        if (updatedRunners[1]?.layPrices && updatedRunners[1]?.backPrices) {
                                            matchItem.matchodd[0].runners[1].layPrices = updatedRunners[1].layPrices;
                                            matchItem.matchodd[0].runners[1].backPrices = updatedRunners[1].backPrices;
                                        }
                                    } else if (matchItem.matchodd[0].runners.length === 3) {
                                        // Check if updatedExchangeItem.matchodd.runners[0], [1], and [2] are defined
                                        if (updatedRunners[0]?.layPrices && updatedRunners[0]?.backPrices) {
                                            matchItem.matchodd[0].runners[0].layPrices = updatedRunners[0].layPrices;
                                            matchItem.matchodd[0].runners[0].backPrices = updatedRunners[0].backPrices;
                                        }
                                        if (updatedRunners[1]?.layPrices && updatedRunners[1]?.backPrices) {
                                            matchItem.matchodd[0].runners[1].layPrices = updatedRunners[1].layPrices;
                                            matchItem.matchodd[0].runners[1].backPrices = updatedRunners[1].backPrices;
                                        }
                                        if (updatedRunners[2]?.layPrices && updatedRunners[2]?.backPrices) {
                                            matchItem.matchodd[0].runners[2].layPrices = updatedRunners[2].layPrices;
                                            matchItem.matchodd[0].runners[2].backPrices = updatedRunners[2].backPrices;
                                        }
                                    }
                                } else {
                                    console.error('updatedExchangeItem.matchodd.runners is undefined or empty');
                                }
                            } else {
                                console.error('matchItem.matchodd[0].runners is not an array');
                            }
                        }
                    }
                }


                if(matchItem.match.eventId == "33847636"){

                }
                // console.log(matchItem.match.status,'~~~~~~~',matchItem);

                // Update other properties as needed
            }

            return (
                <>
                {((shouldShow && types === 'competitions') || (shouldShow && ["7","4339"].includes(matchItem.match.sportId))) ? <div className='series-title'>{currentCompetitionName}</div>:''}
                    <Link to={`/exchange_inPlay/${matchItem.match.eventId}`} className='match-info-row' key={matchindex}>
                        <div className='match-update'>
                            {matchItem.match.status === 'IN_PLAY' ? <div className="short-score">
                                    <div className='animBorder'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                <div className="block left">
                                    <span>In Play</span>
                                </div>

                            </div> : ''}
                            {matchItem.match.status === 'IN_PLAY' ? <div className='match-live-status'>Live</div> : ''}
                            <div className='match-date'>
                            {dateFormet(matchItem?.match?.openDate)}
                            </div>

                        </div>
                        <div className='match-name'>
                            <strong>{matchItem.match.eventName}</strong>
                            {matchItem.match.status === 'IN_PLAY' ? <div className='match-live-status'>Live</div> : ''}

                            <div className='match-stream'>
                                {matchItem.match.status === 'IN_PLAY' ? <div className='box'> <span><i className="fa-solid fa-tv"></i></span> </div> : ''}
                                {matchItem?.MatchOddCount > 0 ? <div className='box'> <span className='blue-bg'>MO</span> </div> : ''}
                                {matchItem?.BMcount > 0 ? <div className='box'> <span className='blue-bg'>BM</span> </div> : ''}
                                {matchItem?.FANCYcount > 0 ? <div className='box'> <span className='blue-bg'>F</span> </div> : ''}
                                {matchItem?.TossCount > 0 ? <div className='box'> <span className='blue-bg'>T</span> </div> : ''}
                            </div>
                        </div>
                        <div className='match-odds'>
                            <div className='box'>
                                <span className='blue'>
                                {validation(matchItem.matchodd[0]?.runners[0]?.backPrices[0]?.price) ?
                                    matchItem.matchodd[0].runners[0]?.backPrices[0]?.price :
                                      <i className='fa fa-lock'></i>}</span>
                                <span className='pink'>

                                {validation(matchItem.matchodd[0]?.runners[0]?.layPrices[0]?.price) ?
                                     matchItem.matchodd[0]?.runners[0]?.layPrices[0]?.price :
                                      <i className='fa fa-lock'></i>}</span>
                            </div>
                            {matchItem.matchodd[0]?.runners.length === 3 ?
                            <div className='box'>
                            <span className='blue'>
                            {validation(matchItem.matchodd[0]?.runners[2]?.backPrices[0]?.price) ?
                                 matchItem.matchodd[0]?.runners[2]?.backPrices[0]?.price :
                                  <i className='fa fa-lock'></i>}</span>
                            <span className='pink'>

                            {validation(matchItem.matchodd[0]?.runners[2]?.layPrices[0]?.price) ?
                                 matchItem.matchodd[0]?.runners[2]?.layPrices[0]?.price :
                                  <i className='fa fa-lock'></i>}</span>
                        </div> :
                            <div className='box'>
                                <span className='blue'><i className='fa fa-lock'></i></span>
                                <span className='pink'><i className='fa fa-lock'></i></span>
                            </div>}
                            <div className='box'>
                                <span className='blue'>
                                {validation(matchItem.matchodd[0]?.runners[1]?.backPrices[0]?.price) ?
                                     matchItem.matchodd[0]?.runners[1]?.backPrices[0]?.price :
                                      <i className='fa fa-lock'></i>}</span>
                                <span className='pink'>

                                {validation(matchItem.matchodd[0]?.runners[1]?.layPrices[0]?.price) ?
                                     matchItem.matchodd[0]?.runners[1]?.layPrices[0]?.price :
                                      <i className='fa fa-lock'></i>}</span>
                            </div>
                        </div>
                        {/* {location.pathname.includes("exchange")  ? <svg className='star-icon' xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="none">
                                    <path d="M12.3834 1.13606C13.0434 -0.201277 14.9514 -0.201277 15.6114 1.13606L18.7554 7.50672L25.7861 8.52806C27.2621 8.74139 27.8514 10.5574 26.7834 11.5987L25.5754 12.7761C24.0802 11.9352 22.3691 11.557 20.6588 11.6894C18.9485 11.8217 17.3159 12.4586 15.9678 13.5194C14.6198 14.5802 13.6168 16.0172 13.086 17.6484C12.5552 19.2797 12.5204 21.0317 12.9861 22.6827L7.71006 25.4561C6.39006 26.1494 4.84606 25.0294 5.09672 23.5587L6.29806 16.5574L1.21139 11.5974C0.143391 10.5574 0.732724 8.74272 2.20872 8.52806L9.23939 7.50672L12.3834 1.13606ZM28.6634 20.3334C28.6634 18.3885 27.8908 16.5232 26.5155 15.1479C25.1402 13.7727 23.275 13.0001 21.3301 13.0001C19.3851 13.0001 17.5199 13.7727 16.1446 15.1479C14.7693 16.5232 13.9967 18.3885 13.9967 20.3334C13.9967 22.2783 14.7693 24.1436 16.1446 25.5188C17.5199 26.8941 19.3851 27.6667 21.3301 27.6667C23.275 27.6667 25.1402 26.8941 26.5155 25.5188C27.8908 24.1436 28.6634 22.2783 28.6634 20.3334ZM21.9967 21.0001L21.9994 24.3374C21.9994 24.5142 21.9292 24.6838 21.8041 24.8088C21.6791 24.9338 21.5095 25.0041 21.3327 25.0041C21.1559 25.0041 20.9863 24.9338 20.8613 24.8088C20.7363 24.6838 20.6661 24.5142 20.6661 24.3374V21.0001H17.3261C17.1492 21.0001 16.9797 20.9298 16.8547 20.8048C16.7296 20.6798 16.6594 20.5102 16.6594 20.3334C16.6594 20.1566 16.7296 19.987 16.8547 19.862C16.9797 19.737 17.1492 19.6667 17.3261 19.6667H20.6661L20.6647 16.3321C20.6647 16.1552 20.735 15.9857 20.86 15.8607C20.985 15.7356 21.1546 15.6654 21.3314 15.6654C21.5082 15.6654 21.6778 15.7356 21.8028 15.8607C21.9278 15.9857 21.9981 16.1552 21.9981 16.3321V19.6654H25.3274C25.5042 19.6654 25.6738 19.7356 25.7988 19.8607C25.9238 19.9857 25.9941 20.1552 25.9941 20.3321C25.9941 20.5089 25.9238 20.6784 25.7988 20.8035C25.6738 20.9285 25.5042 20.9987 25.3274 20.9987H21.9994L21.9967 21.0001Z" fill="#182EA6"></path>
                                </svg> : ''} */}
                    </Link>
            </>
            )
        }) : (
        <p>Match Not Found</p>
      ))
    }
    

    const renderHRGH = (gameItem) => {
        let lastSeries = null;
        return gameItem.matchData.map((matchItem, matchindex) => {
            let currentCompetitionName
            let shouldShow
            currentCompetitionName = matchItem.match.competitionName.split("-")[0];
            shouldShow = lastSeries !== currentCompetitionName;
            lastSeries = currentCompetitionName;
            return (
                <>
                {shouldShow ? <div className='series-title'>{currentCompetitionName}</div>:''}
                <div className='match-info-row'>
                    <div className='match-name'>
                        <strong>{matchItem.match.eventName}</strong>
                    </div>
                    <div className='match-odds time-tag'>
                        {matchItem.HRGHMO.map((market,marketIndex)=>{
                            let date = new Date(market.marketTime);
                            let isShow = false
                            if(date.getTime() - (1000 * 60 * 15) <= Date.now()){
                                isShow = true
                            }
                            date.setHours(date.getHours() + 5);
                            date.setMinutes(date.getMinutes() + 30);
                            let hours = date.getUTCHours();
                            let minutes = date.getUTCMinutes();
                            let formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                            return (
                                <div className='box'>
                                {isShow
                                ?
                                    <Link to={`/exchange_inPlay/${matchItem.match.eventId}/${market.marketId}`} key={market.marketId}>
                                    <span
                                        key={market.marketId}
                                        className={`odds-tag live`}
                                        id={market.marketId}
                                    >
                                    {formattedTime}
                                    </span>
                                    </Link>
                                :
                                <span
                                    key={market.marketId}
                                    className={`odds-tag`}
                                    id={market.marketId}
                                    >
                                    {formattedTime}
                                </span>
                                }
                            </div>

                            )
                        })
                        }
                    </div>
                </div>
            </>
            );
        })
    }

    return (
        <>


        {/* {updateMatchOdds && updateMatchOdds.game && updateMatchOdds.game.length > 0 ? 
            updateMatchOdds.game.map((gameItem, index) => (
            <div className='sport-all-data' key={index}>
                            <div className='match-wrapper'>
                                <div className='heading'>
                                    <div className='heading-text sport-name'>
                                        {gameItem.game}
                                    </div>
                                    <div className='odds-heading'>
                                        {!["GreyHound","HorseRacing"].includes(gameItem.game)?
                                        <>
                                        <div className='heading-text box'>1</div>
                                        <div className='heading-text box'>X</div>
                                        <div className='heading-text box'>2</div>
                                        </>:
                                        ""
                                        }
                                    </div>
                                </div>

                                {gameItem.matchData && gameItem.matchData.length > 0 ? (
            gameItem.matchData.map((matchItem, matchIndex) => (
            <div key={matchIndex}>
            <div className='match-data'>
                                    <div className='match-list'>
                                        {["GreyHound","HorseRacing"].includes(gameItem.game)?renderHRGH(gameItem):renderData(gameItem)}
                                    </div>
                                </div>
            </div>
            ))
        ) : (
            <div>Match Not Found</div>
        )}
                                
                                
                            </div>
            </div>
        ))
        : (
        <div>No Data Found</div>
        )} */}
            {updateMatchOdds && updateMatchOdds?.game && updateMatchOdds.game.length > 0 ? 
            (updateMatchOdds?.game?.map((gameItem, index) => {
                return (
                    <>
                    {gameItem.matchData && gameItem.matchData.length > 0 ?
                    <div className='sport-all-data' key={index}>
                        <div className='match-wrapper'>
                            <div className='heading'>
                                <div className='heading-text sport-name'>
                                    {gameItem.game}
                                </div>
                                <div className='odds-heading'>
                                    {!["GreyHound","HorseRacing"].includes(gameItem.game)?
                                    <>
                                    <div className='heading-text box'>1</div>
                                    <div className='heading-text box'>X</div>
                                    <div className='heading-text box'>2</div>
                                    </>:
                                    ""
                                    }
                                </div>
                            </div>
                            
                            {gameItem.matchData && gameItem.matchData.length > 0 ?
                            <div className='match-data'>
                                <div className='match-list'>
                                    {["GreyHound","HorseRacing"].includes(gameItem.game)?renderHRGH(gameItem):renderData(gameItem)}
                                </div>
                            </div>
                             : <p className='no-data'>Not available {gameItem.game} matches</p>}
                        </div>
                    </div> :
                    <div className='sport-all-data' key={index}>
                        <div className='match-wrapper'>
                            <div className='heading'>
                                <div className='heading-text sport-name'>
                                    {gameItem.game}
                                </div>
                                </div>
                                <div className='match-data'>
                                <div className='match-list'><p className='no-data'>No inplay events!</p></div></div>
                                
                                </div>
                                </div>}</>
                )
            })):<p className='no-data'>No data available</p>}

        </>
    )

}

export default Allsportdata;



