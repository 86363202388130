import React, { useContext, useEffect, useState } from "react";
import "./menudropdownpopup.css";
import { Modal } from "react-bootstrap";
import Popup from "../common/popup/Popup";
import { PopupContext } from "../LoginPopup/LoginPopupContext";

function OneClickPopup({ isopen, handleClose }) {
  const token = sessionStorage.getItem("token");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const { clearDialog,openDialogSet,openDialog } = useContext(PopupContext);

  const [isChecked, setIsChecked] = useState(() => {
    const storedValue = sessionStorage.getItem("isOneClicked");
    return storedValue === "true";
  });

  const [amount, setAmount] = useState(() => {
    const storedAmount = sessionStorage.getItem("oneClickAmount");
    return storedAmount ? parseInt(storedAmount, 10) : 0;
  });

  const handleCheckboxChange = (e) => {
   setIsChecked(e.target.checked);
   updateOneClick(e.target.checked);
  };
  useEffect(() => {
    if(openDialog === 'one_click'){
      setIsChecked(sessionStorage.getItem("isOneClicked") === "true");
      setAmount(sessionStorage.getItem("oneClickAmount") ? parseInt(sessionStorage.getItem("oneClickAmount"), 10) : 0);
    }
  }, [openDialog])


  const updateOneClick = (isChecked) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify({
      status: isChecked,
      stack: amount,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bets/setOneClickData`,
      requestOptions
    )
      .then(async (response) => {
        let paresData = await response.json();
        if(paresData.status !== 'Error'){
          setIsUpdate(true);
          sessionStorage.setItem('isOneClicked',isChecked);
          sessionStorage.setItem('oneClickAmount',amount);
          setTimeout(() => {
            setIsUpdate(false);
            handleClose();
          }, 2000);
        }
        console.log("setOneClickData - ", paresData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAmount = (amount) => {
    setAmount(amount);
  };

  return (
    <>
      <Modal
        show={isopen}
        onHide={handleClose}
        className="menu-dropdown-popup one-click-popup"
        dialogClassName="v-center"
      >
        <Modal.Header closeButton>
          <h2>
            <span className="title-v-border"></span> One Click Bet
          </h2>
        </Modal.Header>
        <Modal.Body>
          <div className="one-bet-click-wraper">
            {(
              <div className="bet-value-add">
                <div className="input-box">
                  <input
                    type="number"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                <div className="bet-slip-btn">
                  <span
                    id="100"
                    onClick={() => handleAmount(100)}
                    className="oneClicSpan"
                  >
                    100
                  </span>
                  <span
                    id="500"
                    onClick={() => handleAmount(500)}
                    className="oneClicSpan"
                  >
                    500
                  </span>
                  <span
                    id="1000"
                    onClick={() => handleAmount(1000)}
                    className="oneClicSpan"
                  >
                    1000
                  </span>
                  <span
                    id="10000"
                    onClick={() => handleAmount(10000)}
                    className="oneClicSpan"
                  >
                    10000
                  </span>
                </div>
              </div>
            )}


            

            <div className="btn-sec">

            <div className="input-box">
              <label htmlFor="oneclickbet" className="form-label">
                One Click Bet
              </label>
              <div className="on-off-btn-section">
                <span className="on-off">
                  OFF &nbsp;{" "}
                  <label className={`switch ${isChecked ? 'on' : '' }`}>
                    <input
                      className="checkbox onclickStatus"
                      name="oneclickbet"
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e)=>handleCheckboxChange(e)}
                    />
                    <span className="slider round"></span>
                  </label>
                  &nbsp; ON
                </span>
              </div>
            </div>

              {/* <button className="btn" onClick={updateOneClick}>
                UPDATE CHANGES
              </button> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Popup
        popupClass={`alertMsgpopup ${isUpdate ? "open" : ""}`}
        content={
          <div className={`alert-msg success`}>
            <span className="msg">One Click Bet Update Successfully!!</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
      <Popup
        popupClass={`alertMsgpopup ${isError ? "open" : ""}`}
        content={
          <div className={`alert-msg err`}>
            <span className="msg">{errorMsg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </>
  );
}

export default OneClickPopup;
