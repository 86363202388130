import React, { useContext, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Popup from "../common/popup/Popup";
import { useEffect } from "react";
import { PopupContext } from "../LoginPopup/LoginPopupContext";
import bankTransfer from "../../assets/images/bank-transfer.png";
import upiPay from "../../assets/images/upi-pay.png";
import paytmPay from "../../assets/images/paytm-pay.png";
import { Link, useNavigate } from 'react-router-dom'
import Tesseract from "tesseract.js";
import { QRCodeCanvas } from 'qrcode.react';

export default function WithdrawPopup({ isopen, handleClose }) {
  const token = sessionStorage.getItem("token");
  const [isUpdate, setIsUpdate] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const initialized = useRef(false);  
  const navigate = useNavigate();
  const [paymentTab, setPaymentTab] = useState("tab1");
  const [accountTab, setAccountTab] = useState(0);
  const [currentAccount, setCurrentAccount] = useState();
  const [isBankTransfer, setIsBankTransfer] = useState([]);
  const [isUPI, setIsUPI] = useState([]);
  const [isPaytm, setIsPaytm] = useState([]);
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false); // Track if form is submitted

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initialize an errors object
    let validationErrors = {};

    // Check if the fields are filled out
    if (!amount) {
      validationErrors.amount = 'Amount is required';
    }
    if (!note) {
      validationErrors.note = 'Notes are required';
    }

    if (Object.keys(validationErrors).length > 0) {
      // If there are errors, set them to state
      setErrors(validationErrors);

      // Hide the errors after 2 seconds
      setTimeout(() => {
        setErrors({});
      }, 2000);
    } else {
      setIsSubmitted(true);
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");
      
      const raw = JSON.stringify({
        "id": currentAccount._id,
        "amount": amount,
        "notes": note
      });
      
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/payment/withdrawreq`, requestOptions)
      .then(async (response) => {
        let paresData = await response.json();
        setIsSubmitted(false); 
        if (response.ok) {
          setIsUpdate(true);
          setTimeout(() => {
            setIsUpdate(false);
            handleClose();
          }, 3000);  
          initialized.current = false;
        }else{
          setIsError(true);
          setErrorMsg(paresData.message);
          setTimeout(() => {
            setIsError(false);
          });
        }
      })
      .catch((error) => {
        setIsSubmitted(false); 
        console.log(error);
      });
    }
  };

  const getPaymentDetails = () => {
    const myHeaders = new Headers();
    // myHeaders.append("Authorization", "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJBIjoiNjczYzYzY2NmNjU0ZmQxYjYyOGY3ODM4IiwiaWF0IjoxNzM3MzUxNjUxLCJleHAiOjE3MzkxNTE2NTF9.UZtwCY075h8vN0TKomgLxGHElBpuR1cc_WbEkUqGk4c");
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      "page": "0",
      "pmethod": "All"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/payment/paymentmethodlist`, requestOptions)
    .then(async (response) => {
      let paresData = await response.json();
        if (response.ok) {
          if (paresData && paresData.paymentMethodList != 0) {
            let bank = paresData.paymentMethodList.filter(item => item.pmethod === "banktransferW" && item.status);
            let upi = paresData.paymentMethodList.filter(item => item.pmethod === "upiW" && item.status);
            let paytm = paresData.paymentMethodList.filter(item => item.pmethod === "paytmW" && item.status);
            setIsBankTransfer(bank)
            setIsUPI(upi)
            setIsPaytm(paytm)
            if (paymentTab === 'tab1') {
              if (bank.length > 0) {
                setCurrentAccount(bank[0]);                
              }else{
                setCurrentAccount();
              }
            }else if (paymentTab === 'tab2') {
              if (upi.length > 0) {
                setCurrentAccount(upi[0]);
              }else{
                setCurrentAccount();
              }
            }else if (paymentTab === 'tab3') {
              if (paytm.length > 0) {
                setCurrentAccount(paytm[0]);
              }else{
                setCurrentAccount();
              }
            }
          } else {
            
          }
        }
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (isopen && !initialized.current) {
      initialized.current = true;
      getPaymentDetails();
    }
    
  }, [isopen,currentAccount]);

  const handleTabChnage =(tab) => {
    setPaymentTab(tab);
    setAccountTab(0);
    if (tab === "tab1") {
      setCurrentAccount(isBankTransfer[0]);
    }else if(tab === "tab2"){
      setCurrentAccount(isUPI[0]);
    }else if(tab === "tab3"){
      setCurrentAccount(isPaytm[0]);
    }
  }

  const handleAccountTab = (index,item) =>{
    setAccountTab(index);
    setCurrentAccount(item);
  }

  const getCurrentAccount = () => {
    if (paymentTab === "tab1") {
      if (currentAccount) {
       return <> <ul id="BANK-DATA">
          <li id="Acc-Name">{currentAccount.accountholdername}<span className="copy-icon"></span></li>
          <li id="Acc-Number">{currentAccount.accountnumber}<span className="copy-icon"></span></li>
          <li id="Bank-Name">{currentAccount.bankname}<span className="copy-icon"></span></li>
          <li id="IFSC">IFSC : {currentAccount.ifsccode}<span className="copy-icon"></span></li>
        </ul>
        {getLink()}</>
      }
    }else if(paymentTab === "tab2"){
      if (currentAccount) {
        return <><ul id="BANK-DATA">
          <li id="Acc-Name">{currentAccount.accountholdername}<span className="copy-icon"></span></li>
          <li id="Acc-Number">{currentAccount.upiid}<span className="copy-icon"></span></li>
          <QRCodeCanvas
            value={currentAccount.upiid}
            size={150} // size of the QR code
            bgColor="#ffffff" // background color
            fgColor="#000000" // foreground color
            level="Q" // error correction level
          />
        </ul>
        {getLink()}</>
      }
    }else if(paymentTab === "tab3"){
      if (currentAccount) {
        return <><ul id="BANK-DATA">
          <li id="Acc-Name">{currentAccount.accountholdername}<span className="copy-icon"></span></li>
          <li id="Acc-Number">{currentAccount.phonenumber}<span className="copy-icon"></span></li>
        </ul>
        {getLink()}</>
      }
    }
  }

  const getMainForm = () => {
    return <>
    <div className="enter-payment-detail">
    <div className="title">Enter withdraw Details</div>
    <form className="payment-fom" method="post">
    <div className="input-box">
    <input type="number" placeholder="Enter Amount" value={amount} onChange={(e) => {setAmount(e.target.value)}} required/>
    {errors.amount && <span className="error">{errors.amount}</span>}</div>
    <div className="input-box">
    <input type="text" placeholder="Enter Notes" value={note} onChange={(e) => {setNote(e.target.value)}} required/>
    {errors.note && <span className="error">{errors.note}</span>}</div>
    <div className="btn-sec">
    
    <button
      type="submit"
      disabled={isSubmitted}
      className="btn change-pass-model-form-submit-btn"
      onClick={handleSubmit}>Submit</button>
    </div>
  </form></div></>
  }

  const getForms = () => {
    if (paymentTab === "tab1") {
      if (isBankTransfer.length > 0) {
        return getMainForm()
      }
    }else if (paymentTab === "tab2") {
      if (isUPI.length > 0) {
        return getMainForm()
      }
    }else if (paymentTab === "tab3") {
      if (isPaytm.length > 0) {
        return getMainForm()
      }
    }
  }

  const getLink = () => {
    return <p className="act-not-found">Didn't find the account?<span className="payment-mng-link" onClick={()=>{navigate("/manageAccounts"); handleClose(); initialized.current = false;}}> Click here to add new</span></p>
  }

  const getTab = () => {
    if (paymentTab === "tab1") {
      if (isBankTransfer.length > 0) {
        return <div className="accountnamecontainer">
            {isBankTransfer.map((item,index)=>(
              <div className={`luck-enterprise-tag ${accountTab === index ? 'active':''}`} onClick={()=>handleAccountTab(index,item)} id={index} key={index}>{item.accountholdername}</div>
            ))}
        </div> 
        
      }else{
        return getLink()
      }
    }else if(paymentTab === "tab2"){
      if (isUPI.length > 0) {
        return <div className="accountnamecontainer">
        {isUPI.map((item,index)=>(
          <div className={`luck-enterprise-tag ${accountTab === index ? 'active':''}`} onClick={()=>handleAccountTab(index,item)} id={index} key={index}>{item.accountholdername}</div>     
        ))}
    </div> 
      }else{
        return getLink()
      }
    }else if(paymentTab === "tab3"){
      if (isPaytm.length > 0) {
        return <div className="accountnamecontainer">
        {isPaytm.map((item,index)=>(
          <div className={`luck-enterprise-tag ${accountTab === index ? 'active':''}`} onClick={()=>handleAccountTab(index,item)} id={index} key={index}>{item.accountholdername}</div>     
        ))}
        </div>
      }else{
        return getLink()
      }
    }
  }

  return (
    <>
      <Modal
        show={isopen}
        onHide={()=>{handleClose(); initialized.current = false;}}
        className="menu-dropdown-popup stack-chnage-popup paymentDepositePopup"
        dialogClassName="v-center"
      >
        <Modal.Header closeButton>
          <h2>
            {" "}
            <span className="title-v-border"></span> Withdraw
          </h2>
        </Modal.Header>
        <Modal.Body>
        <div className="choose-payment-option">
            <div className="payment-tab">
                <div className="payment-tab-list">
                    <span onClick={()=>handleTabChnage("tab1")} className={`img-payment bank-img ${paymentTab === 'tab1' ? 'active':''}`} ><img src={bankTransfer}/></span>
                    <span onClick={()=>handleTabChnage("tab2")} className={`img-payment upi-img ${paymentTab === 'tab2' ? 'active':''}`}><img src={upiPay}/></span>
                    <span onClick={()=>handleTabChnage("tab3")} className={`img-payment pytm-img ${paymentTab === 'tab3' ? 'active':''}`}><img src={paytmPay}/></span>
                    <span className="payment-mng-link" onClick={()=>{navigate("/withdrawalRequest"); handleClose(); initialized.current = false;}}>Past Requests</span>
                </div>
                <div className="payment-tab-content">
                    <div className="title">Choose Beneficiary Account</div>
                    <div className="luck-enterprise">
                    {getTab()}
                    {getCurrentAccount()}
                    </div>
                </div>
                  {getForms()}
            </div>
        </div>
        </Modal.Body>
      </Modal>

      <Popup
        popupClass={`alertMsgpopup ${isUpdate ? "open" : ""}`}
        content={
          <div className={`alert-msg success`}>
            <span className="msg">WITHDRAWAL REQUEST SUBMITTED SUCCESSFULLY.</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />

      <Popup
        popupClass={`alertMsgpopup ${isError ? "open" : ""}`}
        content={
          <div className={`alert-msg err`}>
            <span className="msg">{errorMsg}</span>
            <div className="alert-progress-bar"></div>
          </div>
        }
      />
    </>
  );
}
