import React, { useMemo } from "react";
import MarqueeText from "./MarqueeText";
import Marquee from "react-fast-marquee";

export default function SubNotification({ msg ,isFancy}) {
    const notification = useMemo(() => {
        if (msg && msg !== '') { 
            return msg.replace(/\n/g, "\t");
        }
        return msg;
    }, [msg]);
  let space = "&nbsp;";
  return (
    <>
      {notification && notification !== "" && (
        <div className="notifi" style={{ color: "red", cursor: "default" }}>
        {/* <Marquee pauseOnHover> <span> */}
        {isFancy ? <marquee>
            <span>
              <i className="fa-solid fa-bell"></i> {notification}
            </span>
          </marquee> : <><i className="fa-solid fa-bell"></i> {notification}</>}
              
            {/* </span></Marquee> */}

        {/* <MarqueeText text={notification} /> */}
          {/* <marquee>
            <span>
              <i className="fa-solid fa-bell"></i> {notification}
            </span>
          </marquee> */}
        </div>
      )}
    </>
  );
}
