import React from 'react';
import { Modal } from 'react-bootstrap';

function FancyBookPopup({ isopen, handleClose, receivedBookFancy }) {
    // console.log(receivedBookFancy, 'receivedBookFancy');

    return (
        <Modal
            show={isopen}
            onHide={handleClose}
            className="fancy-popup"
            dialogClassName="v-center"
        >
            <Modal.Header>
                <h2> Fancy Book </h2>
            </Modal.Header>
            <Modal.Body>
                <div className="fancy-book-content">
                    <table>
                        <thead>
                            <tr className="headDetail">
                                <th>Runner Name</th>
                                <th>Profit/Loss</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Ensure map returns the JSX */}
                            {receivedBookFancy?.dataToshow?.length > 0 ? (
                                receivedBookFancy.dataToshow.map((oddItem, index) => (
                                    <tr key={index}>
                                        <td>{oddItem?.message || 'N/A'}</td>
                                        <td className={oddItem?.sum < 0 ? 'red-txt' : 'green-txt'}>
                                            {oddItem?.sum || 0}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="2">No data available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className='close-btn'>
                    <button onClick={handleClose} className='btn'>Close</button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default FancyBookPopup;
