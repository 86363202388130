import React, { useEffect, useState } from 'react'
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import FancySession from './FancySession.js';
import FancyWpMarket from './FancyWpMarket.js';
import FancyOddEven from './FancyOddEven.js';
import OnlyOver from './OnlyOver.js';
import ExchanngeinfoPopup from '../ExchanngeinfoPopup.js';
import FancyWinnigSeats from './FancyWinnigSeats.js';
import FancyBallByBall from './FancyBallByBall.js';
import SubNotification from '../../common/SubNotification.jsx';

function Fancy({ eventAccess, getFancy, getfancyClick, receivedBetlistData ,lottreyArray,myNotification ,eventId}) {
    const [tab, setTab] = useState('all');
    const [grulesPopup, setGrulesPopup] = useState();
    const gruleshandleClose = ()=>{
        setGrulesPopup(false);
    }
    const grulesHandle = ()=>{
        setGrulesPopup(true);
    }

    // console.log(lottreyArray, 'eventAccesseventAccesseventAccesseventAccess');
    


        useEffect(() => {
          // console.log(getFancy,"getFancygetFancygetFancy");
        }, [getFancy])


        function customSort(a, b) {
          const getNumber = (str) => {
              const match = str.match(/^(\d+)/); // Match leading numeric value
              return match ? parseInt(match[0], 10) : Infinity; // Use Infinity for non-numeric values
          };
      
          const numA = getNumber(a.marketName);
          const numB = getNumber(b.marketName);
      
          if (numA !== numB) {
              return numA - numB; // Sort numerically first
          }
      
          return a.marketName.localeCompare(b.marketName); // If numbers are equal, sort alphabetically
      }


    let categorizedData = {
        winningSeatData: [],
        sessonData: [],
        onlyOver: [],
        W_P_Market: [],
        Odd_even: [],
        BallByBall:[]
      };





      if (getFancy && getFancy.length !== 0) {
        getFancy.forEach(item => {
          switch (item.category) {
            case "OVERS":
              categorizedData.sessonData.push(item);
              break;
            case "SINGLE_OVER":
              categorizedData.onlyOver.push(item);
              break;
            case "BATSMAN":
              categorizedData.W_P_Market.push(item);
              break;
              case "WINNING_SEATS":
              categorizedData.winningSeatData.push(item);
              break;
              case "BALL_BY_BALL":
                categorizedData.BallByBall.push(item);
              break;
            case "ODD_EVEN":
              categorizedData.Odd_even.push(item);
              break;
            // Add more cases if needed for other categories
            default:
              break;
          }
        });
        // console.log(categorizedData.sessonData, 'categorizedData.sessonData');
      }
      categorizedData.sessonData.sort(customSort);
      

    return (
        <>
        {eventAccess?.winningSeatStatus === true
        || eventAccess?.ballByBall === true
        || eventAccess?.sessionStatus === true
        || eventAccess?.onlyoverStatus === true
        || eventAccess?.wpmarketStatus === true
        || eventAccess?.oddeventStatus === true ? <div className='sport-all-data inner-match-list fancylist'>
            <div className='match-wrapper'>
                <Accordion transition transitionTimeout={350}>
                <AccordionItem initialEntered header={<div className='heading'>
                    <div className='heading-text sport-name'>
                        Fancy <span className='rulespopup-btn' onClick={(e) => {
            e.stopPropagation(); // Prevent accordion toggle
            grulesHandle(); // Call your function
          }}><i className="fa-solid fa-circle-info"></i></span>
                    </div>
                </div>}>

                <div className='fancytabs'>

                {myNotification && myNotification.length > 0 && <SubNotification isFancy={true} msg={myNotification && myNotification.find(noti => noti.marketId == eventId)?.notifications} />}

                    <div className='fancytab-btns'>
                        <button className={tab === 'all' ? 'active' : ''} onClick={() => setTab('all')}>All</button>
                        {eventAccess?.sessionStatus?<button className={tab === 'session' ? 'active' : ''} onClick={() => setTab('session')}>Session</button>:'' }
                        {eventAccess?.onlyoverStatus?<button className={tab === 'onlyover' ? 'active' : ''} onClick={() => setTab('onlyover')}>Only Over</button>:'' }
                        {eventAccess?.wpmarketStatus?<button className={tab === 'wpmarket' ? 'active' : ''} onClick={() => setTab('wpmarket')}>W/P Market</button>:'' }
                        {eventAccess?.oddeventStatus?<button className={tab === 'oddevn' ? 'active' : ''} onClick={() => setTab('oddevn')}>Odd/Even</button>:'' }
                        {eventAccess?.winningSeatStatus?<button className={tab === 'winnigSeats' ? 'active' : ''} onClick={() => setTab('winnigSeats')}>Winnig Seats</button>:'' }
                        {eventAccess?.ballByBall ?<button className={tab === 'ballByBall' ? 'active' : ''} onClick={() => setTab('ballByBall')}>Ball By Ball</button>:'' }
                    </div>
                    
                    <div className='fancytab-content'>
                        {tab === 'all' ?
                            <>
                                <div className={`fancytab ${tab === 'session' ? '' : 'all'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                                <div className={`fancytab ${tab === 'onlyover' ? '' : 'all'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                                <div className={`fancytab ${tab === 'wpmarket' ? '' : 'all'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                                <div className={`fancytab ${tab === 'oddevn' ? '' : 'all'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                                <div className={`fancytab ${tab === 'winnigSeats' ? '' : 'all'}`}> <FancyWinnigSeats eventAccess={eventAccess} categorizedData={categorizedData?.winningSeatData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                                <div className={`fancytab ${tab === 'ballByBall' ? '' : 'all'}`}> <FancyBallByBall eventAccess={eventAccess} categorizedData={categorizedData?.BallByBall} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            </> : ''
                        }
                        {tab === 'session' ?
                            <>
                            <div className={`fancytab ${tab === 'session' ? '' : 'active'}`}> <FancySession eventAccess={eventAccess}  categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'onlyover' ? '' : 'hide'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'wpmarket' ? '' : 'hide'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'oddevn' ? '' : 'hide'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'winnigSeats' ? '' : 'hide'}`}> <FancyWinnigSeats eventAccess={eventAccess} categorizedData={categorizedData?.winningSeatData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'ballByBall' ? '' : 'hide'}`}> <FancyBallByBall eventAccess={eventAccess} categorizedData={categorizedData?.BallByBall} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                        </> : ''
                        }
                        {tab === 'onlyover' ?
                            <>
                            <div className={`fancytab ${tab === 'session' ? '' : 'hide'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'onlyover' ? '' : 'active'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'wpmarket' ? '' : 'hide'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'oddevn' ? '' : 'hide'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'winnigSeats' ? '' : 'hide'}`}> <FancyWinnigSeats eventAccess={eventAccess} categorizedData={categorizedData?.winningSeatData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'ballByBall' ? '' : 'hide'}`}> <FancyBallByBall eventAccess={eventAccess} categorizedData={categorizedData?.BallByBall} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                        </> : ''
                        }
                        {tab === 'wpmarket' ?
                            <>
                            <div className={`fancytab ${tab === 'session' ? '' : 'hide'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'onlyover' ? '' : 'hide'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'wpmarket' ? '' : 'active'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'oddevn' ? '' : 'hide'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'winnigSeats' ? '' : 'hide'}`}> <FancyWinnigSeats eventAccess={eventAccess} categorizedData={categorizedData?.winningSeatData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                            <div className={`fancytab ${tab === 'ballByBall' ? '' : 'hide'}`}> <FancyBallByBall eventAccess={eventAccess} categorizedData={categorizedData?.BallByBall} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                        </> : ''
                        }
                        {tab === 'oddevn' ?
                              <>
                              <div className={`fancytab ${tab === 'session' ? '' : 'hide'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'onlyover' ? '' : 'hide'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'wpmarket' ? '' : 'hide'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'oddevn' ? '' : 'active'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'winnigSeats' ? '' : 'hide'}`}> <FancyWinnigSeats eventAccess={eventAccess} categorizedData={categorizedData?.winningSeatData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'ballByBall' ? '' : 'hide'}`}> <FancyBallByBall eventAccess={eventAccess} categorizedData={categorizedData?.BallByBall} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                          </> : ''
                        }

                        {tab === 'winnigSeats' ?
                              <>
                              <div className={`fancytab ${tab === 'session' ? '' : 'hide'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'onlyover' ? '' : 'hide'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'wpmarket' ? '' : 'hide'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'oddevn' ? '' : 'hide'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'winnigSeats' ? '' : 'active'}`}> <FancyWinnigSeats eventAccess={eventAccess} categorizedData={categorizedData?.winningSeatData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'ballByBall' ? '' : 'hide'}`}> <FancyBallByBall eventAccess={eventAccess} categorizedData={categorizedData?.BallByBall} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                          </> : ''
                        }

                        {tab === 'ballByBall' ?
                              <>
                              <div className={`fancytab ${tab === 'session' ? '' : 'hide'}`}> <FancySession eventAccess={eventAccess} categorizedData={categorizedData?.sessonData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'onlyover' ? '' : 'hide'}`}> <OnlyOver eventAccess={eventAccess} categorizedData={categorizedData?.onlyOver} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'wpmarket' ? '' : 'hide'}`}> <FancyWpMarket eventAccess={eventAccess} categorizedData={categorizedData?.W_P_Market} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'oddevn' ? '' : 'hide'}`}> <FancyOddEven eventAccess={eventAccess} categorizedData={categorizedData?.Odd_even} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'winnigSeats' ? '' : 'hide'}`}> <FancyWinnigSeats eventAccess={eventAccess} categorizedData={categorizedData?.winningSeatData} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                              <div className={`fancytab ${tab === 'ballByBall' ? '' : 'active'}`}> <FancyBallByBall eventAccess={eventAccess} categorizedData={categorizedData?.BallByBall} getfancyClick={getfancyClick} receivedBetlistData={receivedBetlistData}/> </div>
                          </> : ''
                        }
                    </div>
                </div>
                </AccordionItem>
                </Accordion>
            </div>
        </div> : ''}
        <ExchanngeinfoPopup isOpen={grulesPopup} handleClose={gruleshandleClose} />
        </>
    )
}

export default Fancy